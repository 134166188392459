import { all, put, takeEvery } from 'redux-saga/effects'

import * as ACTIONS from './actions'

export function* showSidebarHandler(action) {
    yield put(ACTIONS.showSidebar.success(action.payload))
}

export function* hideSidebarHandler(action) {
    yield put(ACTIONS.hideSidebar.success(action.payload))
}

export function* setRowsOnPageHandler(action) {
    yield put(ACTIONS.setRowsOnPage.success(action.payload))
}
export function* setPageNumberHandler(action) {
    yield put(ACTIONS.setPageNumber.success(action.payload))
}

export function* watchUiSaga() {
    yield all([
        takeEvery(ACTIONS.showSidebar.TRIGGER, showSidebarHandler),
        takeEvery(ACTIONS.hideSidebar.TRIGGER, hideSidebarHandler),
        takeEvery(ACTIONS.setRowsOnPage.TRIGGER, setRowsOnPageHandler),
        takeEvery(ACTIONS.setPageNumber.TRIGGER, setPageNumberHandler),
    ])
}
