import { baseApi } from '../../apis'
import appConstants from '../../../config/appConstants'

const usersApi = {
    getUsers() {
        return baseApi.get({
            url: '/business/users',
        })
    },
    getUserList() {
        return baseApi.get({
            url: appConstants.api.urls.user.list,
        })
    },
    uploadNewUsersFile(file) {
        const formData = new FormData()
        formData.append('file', file)
        return baseApi.post({
            url: '/business/employees',
            body: formData,
        })
    },

    addUserDetails(data) {
        const body = {
            jsonArr: [data],
        }

        return baseApi.post({
            url: '/business/employees/create',
            body,
        })
    },

    getUserDetails(userId) {
        return baseApi.post({
            url: '/business/userdetails',
            body: { userId: userId },
        })
    },

    deleteUser(idCardNumber, applicationNumber) {
        const body = {}
        if (idCardNumber) {
            body.idCardNumber = idCardNumber
        }
        if (applicationNumber) {
            body.applicationNumber = applicationNumber
        }

        return baseApi.post({
            url: '/business/deleteUser',
            body,
        })
    },

    getNameAndCompany() {
        return baseApi.get({
            url: '/business/userdetails',
        })
    },
    getUser() {
        return baseApi.get({
            url: '/user',
        })
    },
    updateRoleStatus(userId, active) {
        return baseApi.post({
            url: `/user/${userId}/update`,
            body: { active },
        })
    },
    fillUserDetails(data) {
        return baseApi.post({
            url: `/user/details`,
            body: { ...data },
        })
    },
    updateMobile(data) {
        return baseApi.post({
            url: `/user/smsToUpdateMobNumber`,
            body: { ...data },
        })
    },
    verifyMobile(data) {
        return baseApi.post({
            url: `/user/updateMobNumber`,
            body: { ...data },
        })
    },

    addUser(data) {
        return baseApi.post({
            url: `/user`,
            body: { ...data },
        })
    },
    updateRole(userId, roleId) {
        return baseApi.post({
            url: `/user/${userId}/update`,
            body: { role: roleId },
        })
    },
    getPayrollEmployees({ page, search }) {
        const params = []

        if (page) {
            params.push(`page=${page}`)
        }
    
        if (search) {
            params.push(`search=${search}`)
        }
        const url = '/user/employees'
    
        const res_url = params.length ? `${url}?${params.join('&')}` : url
        return baseApi.get({
            url: res_url,
        })
    },
    getPrePrintedPayrollEmployees({ page, search }) {
        const params = []

        if (page) {
            params.push(`page=${page}`)
        }
    
        if (search) {
            params.push(`search=${search}`)
        }
        const url = '/user/preprintedemployees'
    
        const res_url = params.length ? `${url}?${params.join('&')}` : url
        return baseApi.get({
            url: res_url,
        })
    },
    removePayrollEmployee(idCardNumber) {
        const url = '/user/removeEmployee'
        
        return baseApi.post({
            url,
            body: { idCardNumber },
        })
    },
    getFileCounts() {
        return baseApi.get({
            url: '/user/filecounts',
        })
    },
    getUserPromo() {
        return baseApi.get({
            url: '/user/promo',
        }, {
            responseType: 'blob',
        })
    },
    registerUser(data) {
        return baseApi.post({
            url: '/company/signup',
            body: { ...data },
        })
    },

}

export default usersApi
