import axios from 'axios'
import store from '../../../redux/store'
import { authLogOut } from '../../../custom/store/auth/actions'
import { notificationError, notificationSuccess } from '../../../services/messages'
import history from '../../../services/history'


const getCookie = (name)=> {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

// axios.defaults.headers.common["Content-type"] = "application/x-www-form-urlencoded";
axios.defaults.headers.common['Content-Type'] = 'application/json'

axios.interceptors.request.use(function(config) {
    config.headers.common['X-CSRF-Token'] = getCookie('crumb');
    return config
})

axios.interceptors.response.use((response) => {
    if (response && response?.data) {
        if (response?.data?.successResponse) {
            notificationSuccess(response?.data?.successResponse)
        }
        if (response?.data?.redirectTo) {
            history.replace(response?.data?.redirectTo)
        } else if (response?.data?.redirectExt) {
            window.open(response?.data?.redirectExt, '_self')
        }
    }
    return Promise.resolve(response)
}, (error) => {

    if (error.response.status === 401 || error.response.status === 403) {
        store.dispatch(authLogOut())
        return Promise.reject(error)
    }
    const expectedError = error.response && error.response.status >= 400 && error.response.status < 500

    if (!expectedError) {
        notificationError('Somthing failed on the server! Try again later')
        /*toast.error('Somthing failed on the server! Try again later', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
        })*/
    } else {
        if (error?.response?.data?.message) {
            notificationError(error?.response?.data?.message)
            return
        }
    }
    return Promise.reject(error)
})

export const fetchAPI = axios.request

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    patch: axios.patch,
    delete: axios.delete,
}
