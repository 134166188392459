import StoreProd from './storeConfig/store.prod'
import StoreDev from './storeConfig/store.dev'

let store
if (process.env.NODE_ENV === 'production') {
    store = StoreProd
} else {
    store = StoreDev
}

export default store
