import { all, call, put, takeEvery } from 'redux-saga/effects'

import * as ACTIONS from './actions'
import { statsApi } from '../../../custom/services/apis'

export function* getAllStatsHandler(action) {
    yield put(ACTIONS.getAllStats.request(action.payload))
    try {
        yield put(ACTIONS.getBusinessPaymentsStats.trigger())
        yield put(ACTIONS.getBusinessEmployeesStats.trigger())
        yield put(ACTIONS.getBusinessUserEmployeesStats.trigger())
        yield put(ACTIONS.getAllStats.success(action.payload))
    } catch (e) {
        yield put(ACTIONS.getAllStats.failure(e))
    }
}

export function* getPayrollBalanceStatsHandler(action) {
    const {} = action.payload
    yield put(ACTIONS.getPayrollBalanceStats.request(action.payload))
    try {
        const response = yield call(statsApi.getTransactionBalanceStats)
        yield put(ACTIONS.getPayrollBalanceStats.success(response))
    } catch (e) {
        yield put(ACTIONS.getPayrollBalanceStats.failure(e))
    }
}

export function* getPayrollEmployeesStatsHandler(action) {
    const {} = action.payload
    yield put(ACTIONS.getPayrollEmployeesStats.request(action.payload))
    try {
        const response = yield call(statsApi.getUserEmployeesStats)
        yield put(ACTIONS.getPayrollEmployeesStats.success(response))
    } catch (e) {
        yield put(ACTIONS.getPayrollEmployeesStats.failure(e))
    }
}

export function* getPayrollTransactionsStatsHandler(action) {
    const {} = action.payload
    yield put(ACTIONS.getPayrollTransactionsStats.request(action.payload))
    try {
        const counts = yield call(statsApi.getTransactionsCountStats)
        const values = yield call(statsApi.getTransactionsValueStats)
        yield put(ACTIONS.getPayrollTransactionsStats.success({ counts, values }))
    } catch (e) {
        yield put(ACTIONS.getPayrollTransactionsStats.failure(e))
    }
}

export function* getBusinessPaymentsStatsHandler(action) {
    const {} = action.payload
    yield put(ACTIONS.getBusinessPaymentsStats.request(action.payload))
    try {
        const data = yield call(statsApi.getPaymentsStatsCount)
        yield put(ACTIONS.getBusinessPaymentsStats.success(data))
    } catch (e) {
        yield put(ACTIONS.getBusinessPaymentsStats.failure(e))
    }
}

export function* getBusinessEmployeesStatsHandler(action) {
    const {} = action.payload
    yield put(ACTIONS.getBusinessEmployeesStats.request(action.payload))
    try {
        const data = yield call(statsApi.getEmployeesStats)
        yield put(ACTIONS.getBusinessEmployeesStats.success(data))
    } catch (e) {
        yield put(ACTIONS.getBusinessEmployeesStats.failure(e))
    }
}

export function* getBusinessUserEmployeesStatsHandler(action) {
    const {} = action.payload
    yield put(ACTIONS.getBusinessUserEmployeesStats.request(action.payload))
    try {
        const data = yield call(statsApi.getUserEmployeesStats)
        yield put(ACTIONS.getBusinessUserEmployeesStats.success(data))
    } catch (e) {
        yield put(ACTIONS.getBusinessUserEmployeesStats.failure(e))
    }
}

/*
export function* Handler(action) {
    const {} = action.payload
    yield put(ACTIONS.getTransactions.request(action.payload))
    try{
        yield put(ACTIONS.getTransactions.success(action.payload))
    }
    catch (e){
        yield put(ACTIONS.getTransactions.failure(e))
    }
}
*/

export function* watchStatsSaga() {
    yield all([
        takeEvery(ACTIONS.getAllStats.TRIGGER, getAllStatsHandler),
        takeEvery(ACTIONS.getPayrollBalanceStats.TRIGGER, getPayrollBalanceStatsHandler),
        takeEvery(ACTIONS.getPayrollEmployeesStats.TRIGGER, getPayrollEmployeesStatsHandler),
        takeEvery(ACTIONS.getPayrollTransactionsStats.TRIGGER, getPayrollTransactionsStatsHandler),
        takeEvery(ACTIONS.getBusinessPaymentsStats.TRIGGER, getBusinessPaymentsStatsHandler),
        takeEvery(ACTIONS.getBusinessEmployeesStats.TRIGGER, getBusinessEmployeesStatsHandler),
        takeEvery(ACTIONS.getBusinessUserEmployeesStats.TRIGGER, getBusinessUserEmployeesStatsHandler),
    ])
}
