import React from 'react'
import '../../common.css'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import store from '../../redux/store'
import RootRoutes from '../../Router'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Router } from 'react-router-dom'
import history from '../../services/history'

function App() {
    return (
        <Provider store={store}>
            <Router history={history}>
                <RootRoutes />
            </Router>
            <ToastContainer />
        </Provider>
    )
}

export default App
