import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import appConstants from '../../../custom/config/appConstants'
import { makeApiUrl, makeServerUrl } from './baseApi'

const transactions = require('./mock.data/dummy_transactions.mock.json')
const transactions_ready = require('./mock.data/dummy_transactions_ready.mock.json')
const transactions_rejected = require('./mock.data/dummy_transactions_cleared.mock.json')
const transactions_aggregated_ready = require('./mock.data/dummy_ready_aggregated_transactions.mock.json')
const transaction_details = require('./mock.data/dummy_transaction_details.mock.json')

const users = require('./mock.data/dummy_users.mock.json')
const rejections = require('./mock.data/dummy_rejections.mock.json')
const settings = require('./mock.data/dummy_user_settings.mock.json')
const userDetails = require('./mock.data/dummy_user_details.mock.json')
const billing = require('./mock.data/dummy_billing.json')
const cardLimit = require('./mock.data/dummy_card_limit_request.mock.json')
const all_categories = require('./mock.data/dummy_all_categories.json')

let mock = null

export const AddMockData = () => {
    mock = new MockAdapter(axios)

    mock.onGet('/test').reply(200, {
        users: [{ id: 1, name: 'John Smith' }],
    })

    mock.onPost(makeServerUrl(appConstants.api.urls.auth.login), {
        username: 'John',
        password: 'JohnPassword',
    }).reply((config) => {

        return [
            200,
            {
                id: 'aaaaaaa-bbbbbbbb-ccccccccccccc',
                status: '2FA',
            },
        ]
    })

    mock.onPost(makeServerUrl(appConstants.api.urls.auth.smsVerification), {
        smsCode: '12345',
        id: 'aaaaaaa-bbbbbbbb-ccccccccccccc',
    }).reply(200, {
        ok: true,
    })

    mock.onGet(makeApiUrl(appConstants.api.urls.user.index)).reply((config) => {
        return [200, userDetails]
    })
    mock.onGet(makeApiUrl(appConstants.api.urls.user.avatar)).reply((config) => {
        return [200, null]
    })

    mock.onGet(makeApiUrl(appConstants.api.urls.business.userDetails)).reply((config) => {
        return [200, userDetails]
    })


    mock.onPost(makeApiUrl(appConstants.api.urls.business.userDetails), {
        userId: '5c74cf15af3b390016826f56',
    }).reply((config) => {
        return [200, userDetails]
    })

    mock.onGet(makeApiUrl(appConstants.api.urls.business.transactions)).reply((config) => {
        return [200, transactions]
    })

    mock.onGet(
        makeApiUrl(
            `${appConstants.api.urls.business.transactions}/aggregated?approvalStatus=READY` /*{
            approvalStatus: appConstants.transaction.statuses.READY,
        }*/,
        ),
    ).reply((config) => {
        return [200, transactions_aggregated_ready]
    })

    mock.onGet(makeApiUrl('/business/users')).reply((config) => {
        return [200, users]
    })
    mock.onGet(makeApiUrl('/business/settings')).reply((config) => {
        return [200, settings]
    })
    mock.onGet(makeApiUrl(`${appConstants.api.urls.business.transactions}/aggregated?approvalStatus=REJECTED&cleared=false`)).reply(
        (config) => {
            return [200, rejections]
        },
    )

    mock.onGet(makeApiUrl(`${appConstants.api.urls.business.transactions}/subscription`)).reply((config) => {
        return [200, billing]
    })
    mock.onGet(makeApiUrl('/business/cardRequests?status=CREATED')).reply((config) => {
        return [200, cardLimit]
    })
    mock.onGet(makeApiUrl('/business/cardRequests?userId=5c74cf15af3b390016826f56')).reply((config) => {
        return [200, cardLimit]
    })

    mock.onGet(
        makeApiUrl(`${appConstants.api.urls.business.transactions}?userId=5c74cf15af3b390016826f56&month=2020-01&approvalStatus=READY`),
    ).reply((config) => {
        return [200, transactions_ready]
    })

    mock.onGet(makeApiUrl(`${appConstants.api.urls.business.transactions}?userId=5c74cf15af3b390016826f56`)).reply((config) => {
        return [200, transactions_ready]
    })

    mock.onGet(
        makeApiUrl(
            `${appConstants.api.urls.business.transactions}?userId=5c74cf15af3b390016826f56&month=2020-01&approvalStatus=REJECTED&cleared=false`,
        ),
    ).reply((config) => {
        return [200, transactions_rejected]
    })
    mock.onGet(makeApiUrl(appConstants.api.urls.business.transactionCategories)).reply((config) => {
        return [200, all_categories]
    })

    mock.onGet(makeApiUrl(`${appConstants.api.urls.business.transactions}?transactionId=5e1c36b3e2aa5fd56518cbc2`)).reply((config) => {
        return [200, transaction_details]
    })
}
