import { default as http } from '../../../custom/services/apis/http'
import { getApiUrl, getServerUrl } from '../../../custom/config/envConfig'
//import { fetchAPI, fetchExternalAPI } from './fetch'
import { AddMockData } from '../../../custom/services/apis/mock-api-data'
import { toast } from 'react-toastify'

export const makeApiUrl = (url) => `${getApiUrl()}${url}`
export const makeServerUrl = (url) => `${getServerUrl()}${url}`

if (process.env.REACT_APP_MOCK_DATA) {
    AddMockData()
}

export const checkSuccessStatus = (status) => {
    return status >= 200 && status <= 300
}

const handleResponse = (response, blob) => {
    if (response.status >= 200 && response.status <= 300) {
        //if (response.statusText === 'OK') {
        return response.data
        //}
    }
    return null
}

const handleDownload = (response, blob) => {
    if (response.status >= 200 && response.status <= 300) {
        //if (response.statusText === 'OK') {

        const contentDisposition = response.headers['content-disposition']

        const fileResult = (/filename=(.*?);/g).exec(contentDisposition)
        let filename = ''
        if (fileResult.length > 1) {
            filename = fileResult[1]
        }

        return {
            file: response.data,
            filename,
        }
        //}
    }
    return null
}

const handleError = (error) => {
    if (error.response && error.response.status >= 400 && error.response.status < 500) {
        //if (response.statusText === 'OK') {
        if (error?.response?.data?.message) {
            toast.error(error?.response?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
            })
            return
        }
        return Promise.reject(error)
        //}
    }
    return null
}

const baseApi = {
    get({ url, body }, config = {}) {
        return http.get(makeApiUrl(url), { ...config }).then(function(response) {
            return handleResponse(response)
        })
        /*return fetchAPI({
            url: makeApiUrl(url),
            method: 'GET',
            authenticated: true,
            body,
            ...config,
        })*/
    },
    getSite({ url, body }, config = {}) {
        return http.get(makeServerUrl(url), { ...config }).then(function(response) {
            return handleResponse(response)
        })
        /*return fetchAPI({
            url: makeApiUrl(url),
            method: 'GET',
            authenticated: true,
            body,
            ...config,
        })*/
    },

    getDownloadSite({ url, body }, config = {}) {
        return http.get(makeServerUrl(url), { ...config }).then(function(response) {
            return handleDownload(response)
        })
        /*return fetchAPI({
            url: makeApiUrl(url),
            method: 'GET',
            authenticated: true,
            body,
            ...config,
        })*/
    },

    post({ url, body }, config = {}) {
        return http.post(makeApiUrl(url), { ...body }, { ...config }).then(function(response) {
            return handleResponse(response)
        })
    },
    postSite({ url, body }, config = {}) {
        return http.post(makeServerUrl(url), { ...body }, { ...config }).then(function(response) {
            return handleResponse(response)
        })
    },

    put({ url, body }, config = {}) {
        return http.put(makeApiUrl(url), { ...body }, { ...config }).then(function(response) {
            return handleResponse(response)
        })
    },

    patch({ url, body }, config = {}) {
        return http.patch(makeApiUrl(url), { ...body }, { ...config }).then(function(response) {
            return handleResponse(response)
        })
    },

    delete({ url, body }, config = {}) {
        return http.delete(makeApiUrl(url), { ...body }, { ...config }).then(function(response) {
            return handleResponse(response)
        })
    },
}

export default baseApi
