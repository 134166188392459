import { combineReducers } from 'redux'
import sidebarBgColor from './sidebarBgColor'
import sidebarImage from './sidebarImage'
import sidebarImageUrl from './sidebarImageUrl'
import sidebarSize from './sidebarSize'
import sidebarCollapsed from './sidebarCollapsed'

const customizer = combineReducers({
    sidebarBgColor,
    sidebarImage,
    sidebarImageUrl,
    sidebarSize,
    sidebarCollapsed,
})

export default customizer
