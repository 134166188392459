import produce from 'immer'
import * as ACTIONS from './actions'

export const initialState = {
    isLoading: false,
    users: [],
    userDetails: null,
    payrollEmployees: [],
    numOfPages: 0,
    search: '',
    page: 0,
    fileCounts: {},
    userPromo: null,
}

/** @reducer */
export default (initState = { ...initialState }, action = {}) =>
    produce(initState, (state) => {
        switch (action.type) {
            case ACTIONS.getUsers.REQUEST: {
                state.isLoading = true
                return
            }
            case ACTIONS.getUsers.SUCCESS: {
                const { data } = action.payload
                state.isLoading = false
                if (data && data.employerRegistrations) {
                    state.users = [...data.employerRegistrations]
                }

                return
            }
            case ACTIONS.getUsers.FAILURE: {
                state.isLoading = false
                return
            }
            case ACTIONS.getUserDetails.REQUEST: {
                state.userDetails = null
                state.isLoading = true
                return
            }
            case ACTIONS.getUserDetails.SUCCESS: {
                const { data } = action.payload
                state.isLoading = false
                state.userDetails = { ...data }
                return
            }
            case ACTIONS.getUserDetails.FAILURE: {
                state.isLoading = false
                return
            }
    
            case ACTIONS.getPayrollEmployees.REQUEST: {
                state.isLoading = true
                return
            }
            case ACTIONS.getPayrollEmployees.SUCCESS: {
                const { employees, numOfPages, page, search } = action.payload
                state.isLoading = false
                state.payrollEmployees = [ ...employees ]
                state.numOfPages = numOfPages
                state.page = page
                state.search = search
                return
            }
            case ACTIONS.getPayrollEmployees.FAILURE: {
                state.isLoading = false
                state.payrollEmployees = []
                return
            }
            case ACTIONS.getPrePrintedPayrollEmployees.REQUEST: {
                state.isLoading = true
                return
            }
            case ACTIONS.getPrePrintedPayrollEmployees.SUCCESS: {
                const { employees, numOfPages, page, search } = action.payload
                state.isLoading = false
                state.payrollEmployees = [ ...employees ]
                state.numOfPages = numOfPages
                state.page = page
                state.search = search
                return
            }
            case ACTIONS.getPrePrintedPayrollEmployees.FAILURE: {
                state.isLoading = false
                state.payrollEmployees = []
                return
            }
            case ACTIONS.getFileCounts.SUCCESS: {
                state.fileCounts = action.payload
                return
            }
            case ACTIONS.getUserPromo.SUCCESS: {
                state.userPromo = action.payload
                return
            }
    
            case ACTIONS.removePayrollEmployee.REQUEST: {
                state.isLoading = true
                return
            }
            case ACTIONS.removePayrollEmployee.SUCCESS:
            case ACTIONS.removePayrollEmployee.FAILURE: {
                state.isLoading = false
                return
            }
    
            default: {
                return
            }
        }
    })
