import { baseApi } from '../../apis'
import appConstants from '../../../../custom/config/appConstants'

const authApi = {
    signUp(config) {
        return baseApi.post(
            {
                url: appConstants.api.urls.auth.login,
                body: { ...config },
            },
            {
                authenticated: false,
            },
        )
    },
    authSmsForRestorePassword(username) {
        return baseApi.post(
            {
                url: appConstants.api.urls.user.forgotPassword.sms,
                body: { username },
            },
            {
                withCredentials: true,
            },
        )
    },
    authResetPassword(currentPassword, newPassword, confirmNewPassword) {
        return baseApi.post(
            {
                url: appConstants.api.urls.user.resetPassword,
                body: { currentPassword, newPassword, confirmNewPassword },
            },
            {
                withCredentials: true,
            },
        )
    },
    authRestorePassword(username, smsCode) {
        return baseApi.post(
            {
                url: appConstants.api.urls.user.forgotPassword.index,
                body: { username, smsCode },
            },
            {
                withCredentials: true,
            },
        )
    },

    login(username, password) {
        return baseApi.postSite(
            {
                url: appConstants.api.urls.auth.login,
                body: { username, password },
            },
            {
                withCredentials: true,
            },
        )
    },
    smsVerification(smsCode, id) {
        return baseApi.postSite(
            {
                url: appConstants.api.urls.auth.smsVerification,
                body: { smsCode, id },
            },
            {
                withCredentials: true,
            },
        )
    },
    getUser() {
        return baseApi.get(
            {
                url: appConstants.api.urls.user.index,
            },
            {
                withCredentials: true,
            },
        )
    },
    getUserAvatar() {
        return baseApi.get(
            {
                url: appConstants.api.urls.user.avatar,
            },
            {
                withCredentials: true,
                responseType: 'blob',
            },
        )
    },


}

export default authApi
