import { baseApi } from '../../apis'

const cardsApi = {
    getActiveCardLimitRequests() {
        return baseApi.get({
            url: '/business/cardRequests?status=CREATED',
        })
    },
    getAllCardLimitRequests() {
        return baseApi.get({
            url: '/business/cardRequests',
        })
    },
    getCardLimitRequestsForUser(userId) {
        return baseApi.get({
            url: `/business/cardRequests?userId=${userId}`,
        })
    },
    approveCardLimitRequest(id) {
        const body = {
            id: id,
            status: 'APPROVED',
        }
        return baseApi.patch({
            url: `/business/cardRequests/update`,
            body,
        })
    },

    rejectCardLimitRequest(id, comment) {
        const body = {
            id: id,
            status: 'REJECTED',
            comment: comment,
        }

        return baseApi.patch({
            url: `/business/cardRequests/update`,
            body,
        })
    },
    updateCardLimit(id, limit, userId) {
        return baseApi.post({
            url: `/business/updateCardLimits`,
            body: { id: id, limit: limit, userId: userId },
        })
    },
    freezeCard(userId) {
        const body = {
            id: userId,
        }
        return baseApi.post({
            url: `/business/freezeCard`,
            body,
        })
    },

    unfreezeCard(userId) {
        const body = {
            id: userId,
        }
        return baseApi.post({
            url: `/business/unfreezeCard`,
            body,
        })
    },
}

export default cardsApi
