import { combineReducers } from 'redux'
import auth from './auth/reducer'
import ui from './ui/reducer'
import transactions from './transactions/reducer'
import users from './users/reducer'
import common from './common/reducer'
import cards from './cards/reducer'
import stats from './stats/reducer'
import payments from './payroll/payments/reducer'

const reducers = combineReducers({
    common,
    auth,
    ui,
    transactions,
    users,
    cards,
    stats,
    payments,
})

export default reducers
