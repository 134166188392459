// const templateConfig = {
//   layoutColor: 'layout-dark', // Options: layout-dark, layout-light - Set layout-dark for dark mode and layout-light for light.
//   // fixedNavbar: true, // Options: true, false - Set true for fixed navbar.
//   sidebar: {
//      collapsed: false, // Options: true, false | Set false to keep the sidebar open / set it to true for collapsed sidebar by default
//      size: 'sidebar-md', // Options: 'sidebar-lg', 'sidebar-md', 'sidebar-sm'
//      backgroundColor: "black",
//      // Gradient Options: 'pomegranate', 'king-yna', 'ibiza-sunset', 'flickr', 'purple-bliss', 'man-of-steel', 'purple-love'
//      // Solid Options: 'black', 'white', 'blue', 'purple', 'red', 'orange', 'navy-blue'
//      backgroundImage: false, // Options: true, false | Set true to show background image
//     //  backgroundImageURL: sidebarBGImage // Change image from sidebarBGImage import
//   },
//   buyNow: false // Options: true, false - To show floating buy now button.
// };
// ​export default templateConfig;
const { REACT_APP_THEME } = process.env

const THEMES = {
    layoutLight: 'layout-light',
    layoutDark: 'layout-dark',
}

const templateConfigLight = {
    layoutColor: THEMES.layoutLight, // Options: layout-dark, layout-light - Set layout-dark for dark mode and layout-light for light.
    // fixedNavbar: true, // Options: true, false - Set true for fixed navbar.
    sidebar: {
        collapsed: false, // Options: true, false | Set false to keep the sidebar open / set it to true for collapsed sidebar by default
        size: 'sidebar-md', // Options: 'sidebar-lg', 'sidebar-md', 'sidebar-sm'
        backgroundColor: 'white',
        // Gradient Options: 'pomegranate', 'king-yna', 'ibiza-sunset', 'flickr', 'purple-bliss', 'man-of-steel', 'purple-love'
        // Solid Options: 'black', 'white', 'blue', 'purple', 'red', 'orange', 'navy-blue'
        backgroundImage: false, // Options: true, false | Set true to show background image
        // backgroundImageURL: sidebarBGImage // Change image from sidebarBGImage import
    },
    buyNow: false, // Options: true, false - To show floating buy now button.
}

const templateConfigDark = {
    layoutColor: THEMES.layoutDark, // Options: layout-dark, layout-light - Set layout-dark for dark mode and layout-light for light.
    // fixedNavbar: true, // Options: true, false - Set true for fixed navbar.
    sidebar: {
        collapsed: false, // Options: true, false | Set false to keep the sidebar open / set it to true for collapsed sidebar by default
        size: 'sidebar-md', // Options: 'sidebar-lg', 'sidebar-md', 'sidebar-sm'
        backgroundColor: 'black',
        // Gradient Options: 'pomegranate', 'king-yna', 'ibiza-sunset', 'flickr', 'purple-bliss', 'man-of-steel', 'purple-love'
        // Solid Options: 'black', 'white', 'blue', 'purple', 'red', 'orange', 'navy-blue'
        backgroundImage: false, // Options: true, false | Set true to show background image
        // backgroundImageURL: sidebarBGImage // Change image from sidebarBGImage import
    },
    buyNow: false, // Options: true, false - To show floating buy now button.
}

const templateConfig = (() => {
    if (REACT_APP_THEME === 'light') {
        document.querySelector('body').classList.add(THEMES.layoutLight)
        return templateConfigLight
    } else {
        document.querySelector('body').classList.add(THEMES.layoutDark)
        return templateConfigDark
    }
})()

export default templateConfig
