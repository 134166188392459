import produce from 'immer'
import * as ACTIONS from './actions'

export const initialState = {
    showSidebar: true,
    rowsOnPage: 15,
    pageNumbers: {},
}

/** @reducer */
export default (initState = { ...initialState }, action = {}) =>
    produce(initState, (state) => {
        switch (action.type) {
            case ACTIONS.showSidebar.SUCCESS: {
                state.showSidebar = true
                return
            }

            case ACTIONS.hideSidebar.SUCCESS: {
                state.showSidebar = false
                return
            }

            case ACTIONS.setRowsOnPage.SUCCESS: {
                const { rows } = action.payload
                state.rowsOnPage = rows
                return
            }

            case ACTIONS.setPageNumber.SUCCESS: {
                const { identifier, pageNumber } = action.payload
                state.pageNumbers = { ...state.pageNumbers, [identifier]: pageNumber }
                return
            }

            default: {
                return
            }
        }
    })
