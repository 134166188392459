import React from 'react'
import './common.css'
import 'react-toastify/dist/ReactToastify.min.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import App from './components/App'

const Root = () => {
    // store.dispatch(loadUser());
    // const loading = store.getState().appReducer.loading;

    return (
        <App />
    )
}

export default Root
