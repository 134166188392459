import React from 'react'
import templateConfig from '../../utility/templateConfig'
import classnames from 'classnames'
import css from './FullpageLayout.module.scss'
import logo from '../../assets/img/logo-small.png'

const OutsideLayout = ({ children, ...rest }) => {
    return (
        <div
            className={classnames('login-layout wrapper outside', css.FullPageLayout, css.Outside, {
                [templateConfig.layoutColor]: !!templateConfig?.layoutColor,
            })}
        >

            <img src={logo} alt='Welcome logo' className={css.NowMoneyLogo} />

            <main className='main'>{children}</main>
        </div>
    )
}

export default OutsideLayout
