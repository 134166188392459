import React, { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { ClipLoader } from 'react-spinners'
import { themeColor } from '../constants/Color'
import css from './Spinners.module.scss'
import ClassNames from 'classnames'

const GlobalSpinner = () => {
    const [loading, setLoading] = useState(true)
    const element = useMemo(() => {
        return document.getElementById('spinner')
    }, [])

    useEffect(() => {
    }, [])

    if (element) {
        return createPortal(
            <div className={css.GlobalSpinner}>
                <div className={ClassNames('sweet-loading', css.SpinnerElement)}>
                    <ClipLoader
                        className='clip-loader'
                        sizeUnit={'px'}
                        size={60}
                        color={themeColor.SECONDARY}
                        loading={loading}
                    />
                </div>
            </div>,
            element,
        )
    }
    return <></>
}

export default GlobalSpinner
