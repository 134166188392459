import types from '../actions/types'

const initialState = {
    isLoading: false,
    employeesData: [],
    companyProfile: {},
    userList: [],
    userRole: [],
    avatar: null,
    error: null,
}
export default function(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case types.LOADING:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.LOADED:
            return {
                ...state,
                isLoading: false,
            }
            break
        case types.FETCH_EMPLOYEES:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.FETCH_EMPLOYEES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                employeesData: payload,
                error: null,
            }
            break
        case types.FETCH_EMPLOYEES_FAILD:
            return {
                ...state,
                isLoading: false,
                error: payload,
            }
            break
        case types.FETCH_COMPANY_PROFILE:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.FETCH_COMPANY_PROFILE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            }
            break
        case types.FETCH_COMPANY_PROFILE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                companyProfile: payload,
                error: null,
            }
            break
        case types.FETCH_USER_LIST:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.FETCH_USER_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                userList: payload,
                error: null,
            }
            break
        case types.FETCH_USER_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            }
            break
        case types.FETCH_ROLES:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.FETCH_ROLES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                userRole: payload,
                error: null,
            }
            break
        case types.FETCH_ROLES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            }
            break
        case types.FETCH_ROLES_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                userRole: payload,
                error: null,
            }
            break
        case types.FETCH_ROLES_UPDATE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            }
            break
        case types.UPDATE_ROLES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                userRole: payload,
                error: null,
            }
            break
        case types.UPDATE_ROLES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            }
            break
        case types.UPDATE_ROLES_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                userList: payload,
                error: null,
            }
            break
        case types.UPDATE_ROLES_STATUS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            }
            break
        case types.UPDATE_PERMISSION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                userRole: payload,
                error: null,
            }
            break
        case types.UPDATE_PERMISSION_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            }
            break
        case types.DOWNLOAD_USER_AVATAR_TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case types.DOWNLOAD_USER_AVATAR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                avatar: payload,
                error: null,
            }
        case types.DOWNLOAD_USER_AVATAR_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            }

        default:
            return state
    }
}
