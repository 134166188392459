import { all, call, put, takeEvery } from 'redux-saga/effects'

import * as ACTIONS from './actions'
import { cardsApi } from '../../../custom/services/apis'

export function* getActiveCardLimitRequestsHandler(action) {
    yield put(ACTIONS.getActiveCardLimitRequests.request(action.payload))
    try {
        const data = yield call(cardsApi.getActiveCardLimitRequests)
        yield put(ACTIONS.getActiveCardLimitRequests.success({ data }))
    } catch (e) {
        yield put(ACTIONS.getActiveCardLimitRequests.failure(e))
    }
}

export function* getAllCardLimitRequestsHandler(action) {
    yield put(ACTIONS.getAllCardLimitRequests.request(action.payload))
    try {
        const data = yield call(cardsApi.getAllCardLimitRequests)
        yield put(ACTIONS.getAllCardLimitRequests.success({ data }))
    } catch (e) {
        yield put(ACTIONS.getAllCardLimitRequests.failure(e))
    }
}

export function* getCardLimitRequestsForUserHandler(action) {
    const { userId } = action.payload
    yield put(ACTIONS.getCardLimitRequestsForUser.request(action.payload))
    try {
        const data = yield call(cardsApi.getCardLimitRequestsForUser, userId)
        yield put(ACTIONS.getCardLimitRequestsForUser.success({ data }))
    } catch (e) {
        yield put(ACTIONS.getCardLimitRequestsForUser.failure(e))
    }
}

export function* approveCardLimitRequestHandler(action) {
    const { id, postAction, postActionParams } = action.payload
    yield put(ACTIONS.approveCardLimitRequest.request(action.payload))
    try {
        const data = yield call(cardsApi.approveCardLimitRequest, id)
        yield put(ACTIONS.approveCardLimitRequest.success({ data }))
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.approveCardLimitRequest.failure(e))
    }
}

export function* rejectCardLimitRequestHandler(action) {
    const { id, comment, postAction, postActionParams } = action.payload
    yield put(ACTIONS.rejectCardLimitRequest.request(action.payload))
    try {
        const data = yield call(cardsApi.rejectCardLimitRequest, id, comment)
        yield put(ACTIONS.rejectCardLimitRequest.success({ data }))
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.rejectCardLimitRequest.failure(e))
    }
}

export function* updateCardLimitHandler(action) {
    const { id, limit, userId, postAction, postActionParams } = action.payload
    yield put(ACTIONS.updateCardLimit.request(action.payload))
    try {
        const data = yield call(cardsApi.updateCardLimit, id, limit, userId)
        yield put(ACTIONS.updateCardLimit.success({ data }))
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.updateCardLimit.failure(e))
    }
}

export function* freezeCardHandler(action) {
    const { userId, postAction, postActionParams } = action.payload
    yield put(ACTIONS.freezeCard.request(action.payload))
    try {
        const data = yield call(cardsApi.freezeCard, userId)
        yield put(ACTIONS.freezeCard.success({ data }))
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.freezeCard.failure(e))
    }
}

export function* unfreezeCardHandler(action) {
    const { userId, postAction, postActionParams } = action.payload
    yield put(ACTIONS.unfreezeCard.request(action.payload))
    try {
        const data = yield call(cardsApi.unfreezeCard, userId)
        yield put(ACTIONS.unfreezeCard.success({ data }))
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.unfreezeCard.failure(e))
    }
}

/*
export function* Handler(action) {
    const {} = action.payload
    yield put(ACTIONS.getTransactions.request(action.payload))
    try{
        yield put(ACTIONS.getTransactions.success(action.payload))
    }
    catch (e){
        yield put(ACTIONS.getTransactions.failure(e))
    }
}
*/

export function* watchCardsSaga() {
    yield all([
        takeEvery(ACTIONS.getActiveCardLimitRequests.TRIGGER, getActiveCardLimitRequestsHandler),
        takeEvery(ACTIONS.getAllCardLimitRequests.TRIGGER, getAllCardLimitRequestsHandler),
        takeEvery(ACTIONS.getCardLimitRequestsForUser.TRIGGER, getCardLimitRequestsForUserHandler),
        takeEvery(ACTIONS.approveCardLimitRequest.TRIGGER, approveCardLimitRequestHandler),
        takeEvery(ACTIONS.rejectCardLimitRequest.TRIGGER, rejectCardLimitRequestHandler),
        takeEvery(ACTIONS.updateCardLimit.TRIGGER, updateCardLimitHandler),
        takeEvery(ACTIONS.freezeCard.TRIGGER, freezeCardHandler),
        takeEvery(ACTIONS.unfreezeCard.TRIGGER, unfreezeCardHandler),
    ])
}
