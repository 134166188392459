import types from '../actions/types'

const initialState = {
    user: null,
    isLoggedIn: false,
    isLoading: true,
    errors: null,
    enforcePasswordReset: false,
}

export default function(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case types.LOGGED_IN:
            return {
                ...state,
                isLoggedIn: true,
            }
        case types.LOGIN_FAILED:
            return {
                ...state,
                user: null,
                errors: payload,
            }
        case types.LOGGED_OUT:
            return {
                ...state,
                user: null,
                isLoggedIn: false,
            }
        case types.FETCH_USER:
            return {
                ...state,
                isLoading: true,
            }

            break
        case types.FETCH_USER_SUCCESS:
            return {
                ...state,
                user: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.FETCH_USER_FAILED:
            return {
                ...state,
                isLoading: false,
            }
            break
        case types.SMS_VERIFICATION_FAILD:
            return {
                ...state,
                user: null,
                isLoggedIn: false,
                isLoading: false,
                errors: payload,
            }

        case types.ENFORCE_PASSWORD_RESET: {
            const { enforcePasswordReset } = action.payload
            return {
                ...state,
                enforcePasswordReset,
            }
        }

        default:
            return state
    }
}
