import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import * as ACTIONS from './actions'
import { usersApi } from '../../services/apis'
import { getUsersPayrollEmployeesPage, getUsersPayrollEmployeesSearch } from './selectors'
import { getDataUrlFromBlob } from '../../services/utils'

export function* getUsersHandler(action) {
    yield put(ACTIONS.getUsers.request(action.payload))
    try {
        const data = yield call(usersApi.getUsers)
        yield put(ACTIONS.getUsers.success({ data }))
    } catch (e) {
        yield put(ACTIONS.getUsers.failure(e))
    }
}

export function* uploadNewUsersFileHandler(action) {
    const { file, postAction, postActionParams } = action.payload
    yield put(ACTIONS.uploadNewUsersFile.request(action.payload))
    try {
        const result = yield call(usersApi.uploadNewUsersFile, file)
        yield put(ACTIONS.uploadNewUsersFile.success(action.payload))
        /*yield call(notificationSuccess,
            'Thank you for your request, it has been sent successfully. You will be contacted by one of our representatives to discuss the further steps.',
            { autoClose: 20000 },
        )*/
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.uploadNewUsersFile.failure(e))
    }
}

export function* addUserDetailsHandler(action) {
    const { data, postAction, postActionParams } = action.payload
    yield put(ACTIONS.addUserDetails.request(action.payload))
    try {
        const result = yield call(usersApi.addUserDetails, data)
        yield put(ACTIONS.addUserDetails.success(action.payload))
        /*yield call(notificationSuccess,
            'Thank you for your request, it has been sent successfully. You will be contacted by one of our representatives to discuss the further steps.',
            { autoClose: 20000 },
        )*/
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.addUserDetails.failure(e))
    }
}

export function* getUserDetailsHandler(action) {
    const { userId } = action.payload
    yield put(ACTIONS.getUserDetails.request(action.payload))
    try {
        const data = yield call(usersApi.getUserDetails, userId)
        yield put(ACTIONS.getUserDetails.success({ data }))
    } catch (e) {
        yield put(ACTIONS.getUserDetails.failure(e))
    }
}

export function* deleteUserHandler(action) {
    const { idCardNumber, applicationNumber, postAction, postActionParams } = action.payload
    yield put(ACTIONS.deleteUser.request(action.payload))
    try {
        const data = yield call(usersApi.deleteUser, idCardNumber, applicationNumber)
        yield put(ACTIONS.deleteUser.success({ data }))
        /*yield call(notificationSuccess,
            'The user deleted successfully',
        )*/
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.deleteUser.failure(e))
    }
}

export function* addUserHandler(action) {
    const {
        fullName,
        username,
        mobileNumber,
        email,
        idCardNumber,
        roleId,
        postAction,
        postActionParams,
    } = action.payload
    yield put(ACTIONS.addUser.request(action.payload))
    try {
        const data = yield call(usersApi.addUser, { fullName, username, mobileNumber, email, idCardNumber, roleId })
        yield put(ACTIONS.addUser.success(action.payload))
        //yield put(getUserList.trigger())
        /*yield call(notificationSuccess,
            'The user created successfully',
        )*/
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.addUser.failure(e))
    }
}

export function* updateRoleHandler(action) {
    const { userId, roleId, postAction, postActionParams } = action.payload
    yield put(ACTIONS.updateRole.request(action.payload))
    try {
        const data = yield call(usersApi.updateRole, userId, roleId)
        yield put(ACTIONS.updateRole.success(action.payload))
        //yield put(getUserList.trigger())
        /*yield call(notificationSuccess,
            'The user role updated successfully',
        )*/
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.updateRole.failure(e))
    }
}


export function* getPayrollEmployeesHandler(action) {
    const { page, search } = action.payload
    yield put(ACTIONS.getPayrollEmployees.request(action.payload))
    try {
        const currSearch = yield select(getUsersPayrollEmployeesSearch)
        const currPage = yield select(getUsersPayrollEmployeesPage)
        const query = {
            page: page ? page : currPage,
            search: (!!search || search === '') ? search : currSearch,
        }
        const result = yield call(usersApi.getPayrollEmployees, query)
        yield put(ACTIONS.getPayrollEmployees.success({ ...result, ...query }))
    } catch (e) {
        yield put(ACTIONS.getPayrollEmployees.failure(e))
    }
}


export function* getPrintedPayrollEmployeesHandler(action) {
    const { page, search } = action.payload
    yield put(ACTIONS.getPrePrintedPayrollEmployees.request(action.payload))
    try {
        const currSearch = yield select(getUsersPayrollEmployeesSearch)
        const currPage = yield select(getUsersPayrollEmployeesPage)
        const query = {
            page: page ? page : currPage,
            search: (!!search || search === '') ? search : currSearch,
        }
        const result = yield call(usersApi.getPrePrintedPayrollEmployees, query)
        yield put(ACTIONS.getPrePrintedPayrollEmployees.success({ ...result, ...query }))
    } catch (e) {
        yield put(ACTIONS.getPrePrintedPayrollEmployees.failure(e))
    }
}

export function* removePayrollEmployeeHandler(action) {
    const { idCardNumber } = action.payload
    yield put(ACTIONS.removePayrollEmployee.request(action.payload))
    try {
        yield call(usersApi.removePayrollEmployee, idCardNumber)
        yield put(ACTIONS.removePayrollEmployee.success(action.payload))
        yield put(ACTIONS.getPayrollEmployees())
    } catch (e) {
        yield put(ACTIONS.removePayrollEmployee.failure(e))
    }
}

export function* getFileCountsHandler(action) {
    yield put(ACTIONS.getFileCounts.request(action.payload))
    try {
        const result = yield call(usersApi.getFileCounts)
        yield put(ACTIONS.getFileCounts.success({ ...result }))
    } catch (e) {
        yield put(ACTIONS.getFileCounts.failure(e))
    }
}

export function* registerUserHandler(action) {
    const { data } = action.payload
    yield put(ACTIONS.registerUser.request(action.payload))
    try {
        const result = yield call(usersApi.registerUser, data)
        yield put(ACTIONS.registerUser.success({ ...result }))
    } catch (e) {
        yield put(ACTIONS.registerUser.failure(e))
    }
}

export function* getUserPromoHandler(action) {
    yield put(ACTIONS.getUserPromo.request(action.payload))
    try {
        const result = yield call(usersApi.getUserPromo)
        if (result) {
            const data = yield call(getDataUrlFromBlob, result)
            yield put(ACTIONS.getUserPromo.success(data))
        }
    } catch (e) {
        yield put(ACTIONS.getUserPromo.failure(e))
    }
}

/*export function* Handler(action) {
    const {} = action.payload
    yield put(ACTIONS.getTransactions.request(action.payload))
    try{
        yield put(ACTIONS.getTransactions.success(action.payload))
    }
    catch (e){
        yield put(ACTIONS.getTransactions.failure(e))
    }
}
*/

export function* watchUsersSaga() {
    yield all([
        takeEvery(ACTIONS.getUsers.TRIGGER, getUsersHandler),
        takeEvery(ACTIONS.uploadNewUsersFile.TRIGGER, uploadNewUsersFileHandler),
        takeEvery(ACTIONS.addUserDetails.TRIGGER, addUserDetailsHandler),
        takeEvery(ACTIONS.getUserDetails.TRIGGER, getUserDetailsHandler),
        takeEvery(ACTIONS.deleteUser.TRIGGER, deleteUserHandler),
        takeEvery(ACTIONS.addUser.TRIGGER, addUserHandler),
        takeEvery(ACTIONS.updateRole.TRIGGER, updateRoleHandler),
        takeEvery(ACTIONS.getPayrollEmployees.TRIGGER, getPayrollEmployeesHandler),
        takeEvery(ACTIONS.getPrePrintedPayrollEmployees.TRIGGER, getPrintedPayrollEmployeesHandler),
        takeEvery(ACTIONS.removePayrollEmployee.TRIGGER, removePayrollEmployeeHandler),
        takeEvery(ACTIONS.getFileCounts.TRIGGER, getFileCountsHandler),
        takeEvery(ACTIONS.registerUser.TRIGGER, registerUserHandler),
        takeEvery(ACTIONS.getUserPromo.TRIGGER, getUserPromoHandler),
    ])
}
