import produce from 'immer'
import * as ACTIONS from './actions'

export const initialState = {
    isLoading: false,
    currentApp: null,
    settings: {},
    //roles: null,
    allRoles: [],
    companyPermissions: null,
    companyUsers: [],
    prevUser: null,
}

/** @reducer */
export default (initState = { ...initialState }, action = {}) =>
    produce(initState, (state) => {
        switch (action.type) {
            case ACTIONS.getSettings.REQUEST:
            case ACTIONS.getUserList.REQUEST: {
                state.isLoading = true
                return
            }
            case ACTIONS.getSettings.SUCCESS: {
                const { data } = action.payload
                state.isLoading = false
                state.settings = { ...data }
                return
            }
            case ACTIONS.getSettings.FAILURE:
            case ACTIONS.getUserList.FAILURE: {
                state.isLoading = false
                return
            }
            case ACTIONS.setCurrentApp.SUCCESS: {
                const { currentApp } = action.payload
                state.currentApp = currentApp
                return
            }
            case ACTIONS.getRole.SUCCESS: {
                const { companyPermissions, roles } = action.payload
                state.companyPermissions = { ...companyPermissions }

                if (roles && roles.length) {
                    //state.roles = { ...roles[ 0 ] }
                    state.allRoles = [...roles]
                } else {
                    //state.roles = {}
                    state.allRoles = []
                }
                return
            }


            case ACTIONS.getUserList.SUCCESS: {
                const { companyUsers } = action.payload
                state.companyUsers = companyUsers
                state.isLoading = false
                return
            }
            case ACTIONS.getPrevUserAndAvatar.SUCCESS: {
                const { user, avatar } = action.payload
                state.prevUser = { user, avatar }
                return
            }
            case ACTIONS.getPrevUserAndAvatar.FAILURE: {
                state.prevUser = null
                return
            }

            case ACTIONS.getDownloadFile.REQUEST: {
                state.isDownloading = true
                return
            }
            case ACTIONS.getDownloadFile.FAILURE: {
                state.isDownloading = false
                return
            }
            case ACTIONS.getDownloadFile.SUCCESS: {
                state.isDownloading = false
                return
            }


            default: {
                return
            }
        }
    })
