import { createActionsCreator } from '../actionCreatorsFactory'

const createAction = createActionsCreator('SAGAS/STATS')

export const getAllStats = createAction('GET_ALL_STATS')
export const getBusinessPaymentsStats = createAction('GET_BUSINESS_PAYMENTS_STATS')
export const getBusinessEmployeesStats = createAction('GET_BUSINESS_EMPLOYEES_STATS')
export const getBusinessUserEmployeesStats = createAction('GET_BUSINESS_USER_EMPLOYEES_STATS')


export const getPayrollTransactionsStats = createAction('GET_PAYROLL_TRANSACTION_STATS')
export const getPayrollBalanceStats = createAction('GET_PAYROLL_BALANCE_STATS')
export const getPayrollEmployeesStats = createAction('GET_PAYROLL_EMPLOYEES_STATS')

