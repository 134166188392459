import { createSelector } from 'reselect'
import get from 'lodash/get'
import appConstants from '../../../custom/config/appConstants'

export const getAuthState = createSelector(
    [
        (state) => {
            return get(state, appConstants.store.routes.auth)
        },
    ],
    (authState) => authState,
)

export const getAuthIsLoggedIn = createSelector([getAuthState], (authState) => {
    try {
        return authState.isLoggedIn
    } catch (e) {
    }
    return false
})
export const getAuthUserAvatar = createSelector([getAuthState], (authState) => {
    try {
        return authState.userAvatar
    } catch (e) {
    }
    return false
})

export const getAuthCurrentUser = createSelector([getAuthState], (authState) => {
    try {
        return authState.user ? authState.user : null
    } catch (e) {
    }
    return null
})

export const getAuthCurrentUserExists = createSelector([getAuthCurrentUser], (user) => {
    try {
        return !!user
    } catch (e) {
    }
    return false
})

export const getAuthCurrentUserName = createSelector([getAuthCurrentUser], (user) => {
    try {
        return user.username ? user.username : ''
    } catch (e) {
    }
    return ''
})

export const getAuthCurrentUserFullName = createSelector([getAuthCurrentUser], (user) => {
    try {
        return user.fullName ? user.fullName : ''
    } catch (e) {
    }
    return ''
})

export const getAuthCurrentUserCompanyTradingName = createSelector([getAuthCurrentUser], (user) => {
    try {
        return user.companyTradingName ? user.companyTradingName : ''
    } catch (e) {
    }
    return ''
})

export const getAuthCurrentUserRole = createSelector([getAuthCurrentUser], (user) => {
    try {
        return user.role ? user.role : {}
    } catch (e) {
    }
    return {}
})

export const getAuthCurrentUserEmail = createSelector([getAuthCurrentUser], (user) => {
    try {
        return user.email ? user.email : ''
    } catch (e) {
    }
    return ''
})

export const getAuthCurrentUserMobileNumber = createSelector([getAuthCurrentUser], (user) => {
    try {
        return user.mobileNumber ? user.mobileNumber : ''
    } catch (e) {
    }
    return ''
})
export const getAuthCurrentUserPrimaryProfile = createSelector([getAuthCurrentUser], (user) => {
    try {

        return user.primaryProfile ? user.primaryProfile : ''
    } catch (e) {
    }
    return ''
})
export const getAuthCurrentUserPrimaryProfileIsBusiness = createSelector([getAuthCurrentUserPrimaryProfile], (primaryProfile) => {
    try {
        return primaryProfile === appConstants.apps.business
    } catch (e) {
    }
    return false
})
export const getAuthCurrentUserPrimaryProfileIsPayroll = createSelector([getAuthCurrentUserPrimaryProfile], (primaryProfile) => {
    try {
        return primaryProfile === appConstants.apps.payroll
    } catch (e) {
    }
    return false
})

export const getAuthCurrentUserRoleBusinessEnabled = createSelector([getAuthCurrentUserRole], (role) => {
    try {
        return role.business ? role.business : false
    } catch (e) {
    }
    return false
})
export const getAuthCurrentUserRolePayrollEnabled = createSelector([getAuthCurrentUserRole], (role) => {
    try {
        return role.display ? role.display : false
    } catch (e) {
    }
    return false
})

