import { all, spawn } from 'redux-saga/effects'

import { watchAuthSaga } from './auth/sagas'
import { watchUiSaga } from './ui/sagas'
import { watchNotificationSaga } from './notifications/sagas'
import { watchTransactionsSaga } from './transactions/sagas'
import { watchUsersSaga } from './users/sagas'
import { watchCommonSaga } from './common/sagas'
import { watchCardsSaga } from './cards/sagas'
import { watchStatsSaga } from './stats/sagas'
import { watchPaymentsSaga } from './payroll/payments/sagas'

function* rootSaga() {
    //console.log('Root SAGA Started!');
    yield all([
        spawn(watchAuthSaga),
        spawn(watchUiSaga),
        spawn(watchNotificationSaga),
        spawn(watchTransactionsSaga),
        spawn(watchUsersSaga),
        spawn(watchCommonSaga),
        spawn(watchCardsSaga),
        spawn(watchStatsSaga),
        spawn(watchPaymentsSaga),
    ])
}

export default rootSaga
