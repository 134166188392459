import { baseApi } from '../../apis'
import appConstants from '../../../../custom/config/appConstants'

const transactionsApi = {
    getTransactions() {
        return baseApi.get({
            url: '/business/transactions',
        })
    },
    getExportAllTransactions() {
        return baseApi.get({
            url: '/business/transactions/export',
        })
    },
    getTransactionsForUser(userId) {
        return baseApi.get({
            url: `/business/transactions?userId=${userId}`,
        })
    },
    getTransaction(id) {
        return baseApi.get({
            url: `/business/transactions?transactionId=${id}`,
        })
    },
    getReadyTransactions(userId, date) {
        const url = `/business/transactions?userId=${userId}&month=${date}&approvalStatus=${appConstants.transaction.statuses.READY}`

        return baseApi.get({
            url: `/business/transactions?userId=${userId}&month=${date}&approvalStatus=${appConstants.transaction.statuses.READY}`,
        })
    },
    markTransactionsAsCleared(transactionIds) {
        const body = {
            transactionIds,
        }

        return baseApi.post({ url: '/business/transactions/clear', body })
    },
    markAllTransactionsAsCleared(userId, date) {
        const body = {
            userId: userId,
            month: date,
        }
        return baseApi.post({ url: '/business/transactions/clear', body })
    },
    changeTransactionStatus(transactionIds, status, reason) {
        const body = {
            transactionIds: transactionIds,
            status: status,
        }
        if (reason) {
            body.comment = reason
        }
        return baseApi.post({ url: '/business/transactions', body })
    },
    changeAllTransactionsStatus(userId, date, status, reason) {
        const body = {
            userId: userId,
            month: date,
            status: status,
        }
        if (reason) {
            body.comment = reason
        }
        return baseApi.post({ url: '/business/transactions', body })
    },
    getTransactionCategories() {
        return baseApi.get({
            url: `/business/transactioncategories`,
        })
    },
    getAllReadyTransactions() {
        return baseApi.get({
            url: `/business/transactions/aggregated?approvalStatus=${appConstants.transaction.statuses.READY}`,
        })
    },
    getExportAllReadyTransctions() {
        //IN SELECTORS
        /*const url = environment.LOCAL_TESTING_SERVER + '/api/v1/business/transactions/aggregated/export?approvalStatus=' + transactionStatuses.READY;
        return url; //  to be used as an href so that the browser will handle the downloading part*/
    },
    getAllRejectedTransactions() {
        return baseApi.get({
            url: `/business/transactions/aggregated?approvalStatus=${appConstants.transaction.statuses.REJECTED}&cleared=false`,
        })
    },

    getExportAllRejectedTransactions() {
        //IN SELECTORS
        //const url = environment.LOCAL_TESTING_SERVER + '/api/v1/business/transactions/aggregated/export?approvalStatus=' + transactionStatuses.REJECTED + '&cleared=false';
        //return url; //  to be used as an href so that the browser will handle the downloading part
    },

    getRejectedTransactions(userId, date) {
        return baseApi.get({
            url: `/business/transactions?userId=${userId}&month=${date}&approvalStatus=${appConstants.transaction.statuses.REJECTED}&cleared=false`,
        })
    },
    getBillingTransactions() {
        return baseApi.get({
            url: `/business/transactions/subscription`,
        })
    },

    downloadTransactionFile(fileName) {
        //IN SELECTORS
        //const url = environment.LOCAL_TESTING_SERVER + '/downloadTransactionFile?fileId=' + fileName;
        //return url; //  to be used as an href so that the browser will handle the downloading part
    },

    downloadInvoice(id) {
        //IN SELECTORS
        //const url = environment.LOCAL_TESTING_SERVER + '/api/v1/transaction/' + id + '/receipt';
        //return url; //  to be used as an href so that the browser will handle the downloading part
    },
}

export default transactionsApi
