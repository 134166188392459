import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import * as ACTIONS from './actions'
import { authGetUser } from './actions'
import { authApi, usersApi } from '../../../custom/services/apis'
import history from '../../../services/history'
import appConstants from '../../../custom/config/appConstants'
import { EventsHub } from '../../../custom/services/Events'
import { getRole } from '../common/actions'
import { getCommonCurrentIsBusiness } from '../common/selectors'

export function* authSignUpHandler(action) {
    const { username, password } = action.payload
    yield put(ACTIONS.authSignUp.request(action.payload))
    try {
        if (username && password) {
            //const data = yield call(authApi.signUp, params)

            //yield call({ context: localStorage, fn: localStorage.setItem }, 'token', token)
            yield put(ACTIONS.authSignUp.success(action.payload))
        }
    } catch (e) {
        yield put(ACTIONS.authSignUp.failure(e))
    }
}

export function* authLogInHandler(action) {
    const { username, password } = action.payload
    yield put(ACTIONS.authLogIn.request(action.payload))
    try {
        const result = yield call(authApi.login, username, password)

        if (result) {
            const { id, status } = result
            if (status === appConstants.login.status.smsVerification) {
                yield call(
                    { context: history, fn: history.push },
                    `${appConstants.routes.auth.smsVerification}?id=${id}`,
                )
            } else if (status === appConstants.login.status.detailsRequired) {
                yield call(
                    { context: history, fn: history.push },
                    `${appConstants.routes.auth.detailsRequired}?id=${id}`,
                )
            } else {
                yield put(ACTIONS.authLogIn.success(action.payload))
                yield put(authGetUser())
                //yield call({ context: history, fn: history.push }, appConstants.routes.root)
            }
        }
    } catch (e) {
        yield put(ACTIONS.authLogIn.failure(e))
        /*if (e.response?.data?.message) {
            yield put(showError(e.response.data))
        }*/
    }
}

export function* authLogOutHandler(action) {
    yield put(ACTIONS.authLogOut.request(action.payload))
    try {
        //yield call({ context: localStorage, fn: localStorage.removeItem }, 'token')
        yield put(ACTIONS.authLogOut.success(action.payload))
        yield call({ context: history, fn: history.push }, appConstants.routes.auth.login)
    } catch (e) {
        yield put(ACTIONS.authLogOut.failure(e))
        /*if (e.response?.data?.message) {
            yield put(showError(e.response.data))
        }*/
    }
}

export function* smsVerificationHandler(action) {
    const { id, smsCode } = action.payload
    yield put(ACTIONS.smsVerification.request(action.payload))
    try {
        const result = yield call(authApi.smsVerification, smsCode, id)
        if (result) {
            yield put(ACTIONS.smsVerification.success(action.payload))
            //yield call({ context: history, fn: history.push }, appConstants.routes.root)
            yield put(authGetUser())
        }
    } catch (e) {
        yield put(ACTIONS.smsVerification.failure(e))
        /*if (e.response?.data?.message) {
            yield put(showError(e.response.data))
        }*/
    }
}

export function* getNameAndCompanyHandler(action) {
    yield put(ACTIONS.getNameAndCompany.request(action.payload))
    try {
        const isBusiness = yield select(getCommonCurrentIsBusiness)
        if(isBusiness) {
            const result = yield call(usersApi.getNameAndCompany)
            yield put(ACTIONS.getNameAndCompany.success(result))
        }
    } catch (e) {
        yield put(ACTIONS.getNameAndCompany.failure(e))
    }
}

export function* authSmsForRestorePasswordHandler(action) {
    const { username, componentId } = action.payload
    yield put(ACTIONS.authSmsForRestorePassword.request(action.payload))
    try {
        const result = yield call(authApi.authSmsForRestorePassword, username)
        EventsHub.emit(appConstants.events.auth.smsForRestorePassword, { data: { result: true }, id: componentId })
        yield put(ACTIONS.authSmsForRestorePassword.success(result))
    } catch (e) {
        EventsHub.emit(appConstants.events.auth.smsForRestorePassword, {
            data: { result: false, error: e },
            id: componentId,
        })
        yield put(ACTIONS.authSmsForRestorePassword.failure(e))
    }
}

export function* authResetPasswordHandler(action) {
    const { currentPassword, newPassword, confirmNewPassword } = action.payload
    yield put(ACTIONS.authResetPassword.request(action.payload))
    try {
        const result = yield call(authApi.authResetPassword, currentPassword, newPassword, confirmNewPassword)
        yield put(ACTIONS.authResetPassword.success(result))
        yield put(ACTIONS.enforcePasswordReset({ enforcePasswordReset: false }))
        yield call({ context: history, fn: history.push }, appConstants.routes.auth.login)
    } catch (e) {
        yield put(ACTIONS.authResetPassword.failure(e))
        /*if (e.response?.data?.message) {
            yield put(showError(e.response.data))
        }*/
    }
}

export function* authRestorePasswordHandler(action) {
    const { username, smsCode } = action.payload
    yield put(ACTIONS.authRestorePassword.request(action.payload))
    try {
        const result = yield call(authApi.authRestorePassword, username, smsCode)
        yield put(ACTIONS.enforcePasswordReset({ enforcePasswordReset: true }))
        yield put(ACTIONS.authRestorePassword.success(result))
        yield call({ context: history, fn: history.push }, appConstants.routes.auth.login)
    } catch (e) {
        yield put(ACTIONS.authRestorePassword.failure(e))
        /*if (e.response?.data?.message) {
            yield put(showError(e.response.data))
        }*/
    }
}

export function* authGetUserHandler(action) {
    const { initLogin } = action.payload
    yield put(ACTIONS.authGetUser.request(action.payload))
    try {
        const user = yield call(authApi.getUser)
        yield put(ACTIONS.authGetUser.success({ user }))
        yield put(getRole.trigger())
        if (user?.enforcePasswordReset) {
            yield put(ACTIONS.enforcePasswordReset({ enforcePasswordReset: true }))
            yield call({ context: history, fn: history.push }, appConstants.routes.auth.changePassword)
        } else {
            if (!initLogin) {
                if (user?.primaryProfile === appConstants.apps.payroll) {
                    yield call({ context: history, fn: history.push }, appConstants.routes.payroll.index)
                } else if (user?.primaryProfile === appConstants.apps.business) {
                    yield call({ context: history, fn: history.push }, appConstants.routes.business.index)
                } else {
                    yield call({ context: history, fn: history.push }, appConstants.routes.root)
                }
            }
        }
    } catch (e) {
        yield put(ACTIONS.authGetUser.failure(e))
    }
}

export function* authGetUserAvatarHandler(action) {
    yield put(ACTIONS.authGetUserAvatar.request(action.payload))
    try {
        const data = yield call(authApi.getUserAvatar)
        yield put(ACTIONS.authGetUserAvatar.success({ avatar: data }))

    } catch (e) {
        yield put(ACTIONS.authGetUserAvatar.failure(e))
    }
}

export function* authFillDetailsDataHandler(action) {
    const { id, fullName, mobileNumber, idCardNumber } = action.payload
    yield put(ACTIONS.authFillDetailsData.request(action.payload))
    try {
        const result = yield call(usersApi.fillUserDetails, { id, fullName, mobileNumber, idCardNumber })
        if (result) {
            const { id, status } = result
            if (status === appConstants.login.status.smsVerification) {
                yield call(
                    { context: history, fn: history.push },
                    `${appConstants.routes.auth.smsVerification}?id=${id}`,
                )
            }
        }
        yield put(ACTIONS.authFillDetailsData.success(action.payload))

    } catch (e) {
        yield put(ACTIONS.authFillDetailsData.failure(e))
    }
}

export function* authUpdateMobileHandler(action) {
    const { mobileNumber } = action.payload
    yield put(ACTIONS.authUpdateMobile.request(action.payload))
    try {
        const result = yield call(usersApi.updateMobile, { mobileNumber })
        if (result) {
            const { status } = result
            if (status === 'OK') {
            }
        }
        yield put(ACTIONS.authUpdateMobile.success(action.payload))

    } catch (e) {
        yield put(ACTIONS.authUpdateMobile.failure(e))
    }
}

export function* authVerifyMobileHandler(action) {
    const { smsCode } = action.payload
    yield put(ACTIONS.authVerifyMobile.request(action.payload))
    try {
        const result = yield call(usersApi.verifyMobile, { smsCode })
        if (result) {
            const { status } = result
            if (status === 'OK') {
                yield put(authGetUser({ initLogin: true }))
            }
        }
        yield put(ACTIONS.authVerifyMobile.success(action.payload))

    } catch (e) {
        yield put(ACTIONS.authVerifyMobile.failure(e))
    }
}

export function* enforcePasswordResetHandler(action) {
    yield put(ACTIONS.enforcePasswordReset.success(action.payload))
}

export function* watchAuthSaga() {
    yield all([
        takeEvery(ACTIONS.authSignUp.TRIGGER, authSignUpHandler),
        takeEvery(ACTIONS.authLogIn.TRIGGER, authLogInHandler),
        takeEvery(ACTIONS.authLogOut.TRIGGER, authLogOutHandler),
        takeEvery(ACTIONS.smsVerification.TRIGGER, smsVerificationHandler),
        takeEvery(ACTIONS.getNameAndCompany.TRIGGER, getNameAndCompanyHandler),
        takeEvery(ACTIONS.authSmsForRestorePassword.TRIGGER, authSmsForRestorePasswordHandler),
        takeEvery(ACTIONS.authResetPassword.TRIGGER, authResetPasswordHandler),
        takeEvery(ACTIONS.authRestorePassword.TRIGGER, authRestorePasswordHandler),
        takeEvery(ACTIONS.enforcePasswordReset.TRIGGER, enforcePasswordResetHandler),
        takeEvery(ACTIONS.authGetUser.TRIGGER, authGetUserHandler),
        takeEvery(ACTIONS.authGetUserAvatar.TRIGGER, authGetUserAvatarHandler),
        takeEvery(ACTIONS.authFillDetailsData.TRIGGER, authFillDetailsDataHandler),
        takeEvery(ACTIONS.authUpdateMobile.TRIGGER, authUpdateMobileHandler),
        takeEvery(ACTIONS.authVerifyMobile.TRIGGER, authVerifyMobileHandler),
    ])
}
