import { applyMiddleware, createStore } from 'redux'

import thunk from 'redux-thunk'

import { composeWithDevTools } from 'redux-devtools-extension'
import { routerMiddleware } from 'react-router-redux'
import createSagaMiddleware from 'redux-saga'
import rootSagas from '../../custom/store/sagas'

import rootReducer from '../rootReducer'
import history from '../../services/history'
//import { logger } from 'redux-logger'

const saga = createSagaMiddleware()
const composeEnhancers = composeWithDevTools({})

const middlewares = [routerMiddleware(history), thunk, saga]
const initialState = {}

const storeDev = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)))

saga.run(rootSagas)

export default storeDev
export { storeDev }
