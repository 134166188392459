import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'react-router-redux'
import createSagaMiddleware from 'redux-saga'
import rootSagas from '../../custom/store/sagas'

import rootReducer from '../rootReducer'
import history from '../../services/history'

const saga = createSagaMiddleware()

const middlewares = [routerMiddleware(history), thunk, saga]
const initialState = {}

const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middlewares)))

saga.run(rootSagas)

export default store

/*
import { createStore, applyMiddleware, compose } from "redux";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";
import createSagaMiddleware from 'redux-saga';
import rootSagas from '../../store/sagas';

const saga = createSagaMiddleware()
const middlewares = [ thunk, saga, createDebounce() ];


const store = createStore(
    rootReducer,
    {},
    compose(applyMiddleware(...middlewares))
);
saga.run(rootSagas)
export default store;



*/
