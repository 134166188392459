import { combineReducers } from 'redux'
import custom from '../custom/store/reducer'
import appReducer from './appReducer'
import authReducer from './authReducer'
import customizer from './customizeReducer/customizeReducer'
import transactionReducer from './transactionReducer'

export default combineReducers({
    custom,
    appReducer,
    authReducer,
    customizer,
    transactionReducer,
})
