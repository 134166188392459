import { createActionsCreator } from '../actionCreatorsFactory'

const createAction = createActionsCreator('SAGAS/USERS')

export const getActiveCardLimitRequests = createAction('getActiveCardLimitRequests')
export const getAllCardLimitRequests = createAction('getAllCardLimitRequests')
export const getCardLimitRequestsForUser = createAction('getCardLimitRequestsForUser')
export const approveCardLimitRequest = createAction('approveCardLimitRequest')
export const rejectCardLimitRequest = createAction('rejectCardLimitRequest')
export const updateCardLimit = createAction('updateCardLimit')
export const freezeCard = createAction('freezeCard')
export const unfreezeCard = createAction('unfreezeCard')
