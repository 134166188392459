import { all, call, put, takeEvery } from 'redux-saga/effects'

import * as ACTIONS from './actions'
import { transactionsApi } from '../../../custom/services/apis'

export function* getTransactionsHandler(action) {
    yield put(ACTIONS.getTransactions.request(action.payload))
    try {
        const data = yield call(transactionsApi.getTransactions)
        yield put(ACTIONS.getTransactions.success({ data }))
    } catch (e) {
        yield put(ACTIONS.getTransactions.failure(e))
    }
}

export function* getExportAllTransactionsHandler(action) {
    yield put(ACTIONS.getExportAllTransactions.request(action.payload))
    try {
        const data = yield call(transactionsApi.getExportAllTransactions)
        yield put(ACTIONS.getExportAllTransactions.success({ data }))
    } catch (e) {
        yield put(ACTIONS.getExportAllTransactions.failure(e))
    }
}

export function* getTransactionsForUserHandler(action) {
    const { userId } = action.payload
    yield put(ACTIONS.getTransactionsForUser.request(action.payload))
    try {
        const data = yield call(transactionsApi.getTransactionsForUser, userId)
        yield put(ACTIONS.getTransactionsForUser.success({ data }))
    } catch (e) {
        yield put(ACTIONS.getTransactionsForUser.failure(e))
    }
}

export function* getTransactionHandler(action) {
    const { id } = action.payload
    yield put(ACTIONS.getTransaction.request(action.payload))
    try {
        const data = yield call(transactionsApi.getTransaction, id)
        yield put(ACTIONS.getTransaction.success({ data }))
    } catch (e) {
        yield put(ACTIONS.getTransaction.failure(e))
    }
}

export function* getReadyTransactionsHandler(action) {
    const { userId, date, postAction, postActionParams } = action.payload
    yield put(ACTIONS.getReadyTransactions.request(action.payload))
    try {
        const data = yield call(transactionsApi.getReadyTransactions, userId, date)
        yield put(ACTIONS.getReadyTransactions.success({ data }))
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.getReadyTransactions.failure(e))
    }
}

export function* markTransactionsAsClearedHandler(action) {
    const { transactionIds, postAction, postActionParams } = action.payload
    yield put(ACTIONS.markTransactionsAsCleared.request(action.payload))
    try {
        const data = yield call(transactionsApi.markTransactionsAsCleared, transactionIds)
        yield put(ACTIONS.markTransactionsAsCleared.success({ data }))
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.markTransactionsAsCleared.failure(e))
    }
}

export function* markAllTransactionsAsClearedHandler(action) {
    const { userId, date, postAction, postActionParams } = action.payload
    yield put(ACTIONS.markAllTransactionsAsCleared.request(action.payload))
    try {
        const data = yield call(transactionsApi.markAllTransactionsAsCleared, userId, date)
        yield put(ACTIONS.markAllTransactionsAsCleared.success({ data }))
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.markAllTransactionsAsCleared.failure(e))
    }
}

export function* changeTransactionStatusHandler(action) {
    const { transactionIds, status, reason, postAction, postActionParams } = action.payload
    yield put(ACTIONS.changeTransactionStatus.request(action.payload))
    try {
        const data = yield call(transactionsApi.changeTransactionStatus, transactionIds, status, reason)
        yield put(ACTIONS.changeTransactionStatus.success(data))
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.changeTransactionStatus.failure(e))
    }
}

export function* changeAllTransactionsStatusHandler(action) {
    const { userId, date, status, reason, postAction, postActionParams } = action.payload
    yield put(ACTIONS.changeAllTransactionsStatus.request(action.payload))
    try {
        const data = yield call(transactionsApi.changeAllTransactionsStatus, userId, date, status, reason)
        yield put(ACTIONS.changeAllTransactionsStatus.success({ data }))
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.changeAllTransactionsStatus.failure(e))
    }
}

export function* getTransactionCategoriesHandler(action) {
    yield put(ACTIONS.getTransactionCategories.request(action.payload))
    try {
        const data = yield call(transactionsApi.getTransactionCategories)
        yield put(ACTIONS.getTransactionCategories.success({ data }))
    } catch (e) {
        yield put(ACTIONS.getTransactionCategories.failure(e))
    }
}

export function* getAllReadyTransactionsHandler(action) {
    yield put(ACTIONS.getAllReadyTransactions.request(action.payload))
    try {
        const data = yield call(transactionsApi.getAllReadyTransactions)
        yield put(ACTIONS.getAllReadyTransactions.success({ data }))
    } catch (e) {
        yield put(ACTIONS.getAllReadyTransactions.failure(e))
    }
}

export function* getAllRejectedTransactionsHandler(action) {
    yield put(ACTIONS.getAllRejectedTransactions.request(action.payload))
    try {
        const data = yield call(transactionsApi.getAllRejectedTransactions)
        yield put(ACTIONS.getAllRejectedTransactions.success({ data }))
    } catch (e) {
        yield put(ACTIONS.getAllRejectedTransactions.failure(e))
    }
}

export function* getExportAllRejectedTransactionsHandler(action) {
    yield put(ACTIONS.getExportAllRejectedTransactions.request(action.payload))
    try {
        const data = yield call(transactionsApi.getExportAllRejectedTransactions)
        yield put(ACTIONS.getExportAllRejectedTransactions.success({ data }))
    } catch (e) {
        yield put(ACTIONS.getExportAllRejectedTransactions.failure(e))
    }
}

export function* getRejectedTransactionsHandler(action) {
    const { userId, date } = action.payload
    yield put(ACTIONS.getRejectedTransactions.request(action.payload))
    try {
        const data = yield call(transactionsApi.getRejectedTransactions, userId, date)
        yield put(ACTIONS.getRejectedTransactions.success({ data }))
    } catch (e) {
        yield put(ACTIONS.getRejectedTransactions.failure(e))
    }
}

export function* getBillingTransactionsHandler(action) {
    yield put(ACTIONS.getBillingTransactions.request(action.payload))
    try {
        const data = yield call(transactionsApi.getBillingTransactions)

        yield put(ACTIONS.getBillingTransactions.success({ data }))
    } catch (e) {
        yield put(ACTIONS.getBillingTransactions.failure(e))
    }
}

/*export function* Handler(action) {
    const {} = action.payload
    yield put(ACTIONS.getTransactions.request(action.payload))
    try{
        yield put(ACTIONS.getTransactions.success(action.payload))
    }
    catch (e){
        yield put(ACTIONS.getTransactions.failure(e))
    }
}*/

export function* watchTransactionsSaga() {
    yield all([
        takeEvery(ACTIONS.getTransactions.TRIGGER, getTransactionsHandler),
        takeEvery(ACTIONS.getExportAllTransactions.TRIGGER, getExportAllTransactionsHandler),
        takeEvery(ACTIONS.getTransactionsForUser.TRIGGER, getTransactionsForUserHandler),
        takeEvery(ACTIONS.getTransaction.TRIGGER, getTransactionHandler),
        takeEvery(ACTIONS.getReadyTransactions.TRIGGER, getReadyTransactionsHandler),
        takeEvery(ACTIONS.markTransactionsAsCleared.TRIGGER, markTransactionsAsClearedHandler),
        takeEvery(ACTIONS.markAllTransactionsAsCleared.TRIGGER, markAllTransactionsAsClearedHandler),
        takeEvery(ACTIONS.changeTransactionStatus.TRIGGER, changeTransactionStatusHandler),
        takeEvery(ACTIONS.changeAllTransactionsStatus.TRIGGER, changeAllTransactionsStatusHandler),
        takeEvery(ACTIONS.getTransactionCategories.TRIGGER, getTransactionCategoriesHandler),
        takeEvery(ACTIONS.getAllReadyTransactions.TRIGGER, getAllReadyTransactionsHandler),
        takeEvery(ACTIONS.getAllRejectedTransactions.TRIGGER, getAllRejectedTransactionsHandler),
        takeEvery(ACTIONS.getExportAllRejectedTransactions.TRIGGER, getExportAllRejectedTransactionsHandler),
        takeEvery(ACTIONS.getRejectedTransactions.TRIGGER, getRejectedTransactionsHandler),
        takeEvery(ACTIONS.getBillingTransactions.TRIGGER, getBillingTransactionsHandler),
    ])
}
