import produce from 'immer'
import * as ACTIONS from './actions'
import moment from 'moment'

export const initialState = {
    isLoading: false,
    payments: null,
    employees: null,
    payrollTransactions: null,
    payrollEmployees: null,
    payrollBalance: null,

    businessBalance: null,
}

/** @reducer */
export default (initState = { ...initialState }, action = {}) =>
    produce(initState, (state) => {
        switch (action.type) {
            case ACTIONS.getAllStats.REQUEST: {
                state.isLoading = true
                return
            }
            case ACTIONS.getAllStats.SUCCESS: {
                const { payments, employees, userEmployees } = action.payload

                state.payments = {
                    chartData: {
                        labels: payments?.labels,
                        series: [payments?.numOfTransactions],
                    },
                    summary: payments?.totalTransactions,
                }


                state.employees = {
                    chartData: {
                        labels: employees?.labels,
                        series: [employees?.numOfEmployees],
                    },
                    summary: employees?.totalEmployees,
                }
                state.userEmployees = {
                    chartData: {
                        labels: employees?.labels,
                        series: [employees?.numOfEmployees],
                    },
                    summary: employees?.totalEmployees,
                }

                state.isLoading = false
                return
            }
            case ACTIONS.getBusinessPaymentsStats.SUCCESS: {
                const { labels, numOfTransactions, totalTransactions } = action.payload

                state.payments = {
                    chartData: {
                        labels: labels,
                        series: [numOfTransactions],
                    },
                    summary: totalTransactions,
                }

                return
            }
            case ACTIONS.getBusinessEmployeesStats.SUCCESS: {
                const { labels, numOfEmployees, totalEmployees } = action.payload

                state.employees = {
                    chartData: {
                        labels: labels,
                        series: [numOfEmployees],
                    },
                    summary: totalEmployees,
                }
                return
            }
            case ACTIONS.getBusinessUserEmployeesStats.SUCCESS: {
                const { labels, numOfEmployees, totalEmployees } = action.payload

                state.userEmployees = {
                    chartData: {
                        labels: labels,
                        series: [numOfEmployees],
                    },
                    summary: totalEmployees,
                }
                return
            }

            case ACTIONS.getAllStats.FAILURE: {
                state.isLoading = false
                return
            }
            case ACTIONS.getPayrollTransactionsStats.SUCCESS: {
                const { counts, values } = action.payload
                state.payrollTransactions = {
                    counts: {
                        chartData: {
                            labels: counts?.labels,
                            series: [counts?.numOfPayments],
                        },

                        summary: counts?.paymentsThisMonth,
                    },
                    values: {
                        chartData: {
                            labels: values?.labels,
                            series: [values?.valueOfPayments],
                        },
                        summary: values?.paymentsValueThisMonth,
                    },
                }
                return
            }
            case ACTIONS.getPayrollBalanceStats.SUCCESS: {
                const { totalBalance, availableBalance } = action.payload
                const labels = totalBalance?.map(i => {
                    return moment(i.datetime.substring(10, 0)).format('DD.MM.YYYY')

                })
                const balance = totalBalance?.map(i => i.balance)
                state.payrollBalance = {
                    chartData: {
                        xAxesType: 'time',
                        labels,
                        series: [balance],
                    },
                    summary: availableBalance,

                }
                return
            }
            case ACTIONS.getPayrollEmployeesStats.SUCCESS: {
                const { labels, numOfEmployees, totalEmployees } = action.payload
                state.payrollEmployees = {
                    chartData: {
                        labels,
                        series: [numOfEmployees],
                    },
                    summary: totalEmployees,

                }
                return
            }
            default: {
                return
            }
        }
    })
