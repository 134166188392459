import produce from 'immer'
import * as ACTIONS from './actions'

export const initialState = {
    isLoading: false,
    cards: [],
}

/** @reducer */
export default (initState = { ...initialState }, action = {}) =>
    produce(initState, (state) => {
        switch (action.type) {
            case ACTIONS.getActiveCardLimitRequests.REQUEST:
            case ACTIONS.getCardLimitRequestsForUser.REQUEST:
            case ACTIONS.getAllCardLimitRequests.REQUEST: {
                state.isLoading = true
                return
            }
            case ACTIONS.getActiveCardLimitRequests.SUCCESS:
            case ACTIONS.getCardLimitRequestsForUser.SUCCESS:
            case ACTIONS.getAllCardLimitRequests.SUCCESS: {
                const { data } = action.payload
                state.isLoading = false
                state.cards = [...data]
                return
            }
            case ACTIONS.getActiveCardLimitRequests.FAILURE:
            case ACTIONS.getCardLimitRequestsForUser.FAILURE:
            case ACTIONS.getAllCardLimitRequests.FAILURE: {
                state.isLoading = false
                return
            }

            default: {
                return
            }
        }
    })
