import { all, call, takeEvery } from 'redux-saga/effects'

import * as ACTIONS from './actions'
import { toast } from 'react-toastify'

export function* showErrorHandler(action) {
    const { message } = action.payload
    yield call({ context: toast, fn: toast.error }, message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
    })
}

export function* showMessageHandler(action) {
    const { message } = action.payload
    yield call({ context: toast, fn: toast.info }, message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
    })
}

export function* showWarningHandler(action) {
    const { message } = action.payload
    yield call({ context: toast, fn: toast.warning }, message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
    })
}

export function* watchNotificationSaga() {
    yield all([
        takeEvery(ACTIONS.showError.TRIGGER, showErrorHandler),
        takeEvery(ACTIONS.showMessage.TRIGGER, showMessageHandler),
        takeEvery(ACTIONS.showWarning.TRIGGER, showWarningHandler),
    ])
}
