import produce from 'immer'
import * as ACTIONS from './actions'

export const initialState = {
    isLoading: false,
    transactions: [],
    billing: [],
    categories: [],
    transaction: null,
}

/** @reducer */
export default (initState = { ...initialState }, action = {}) =>
    produce(initState, (state) => {
        switch (action.type) {
            case ACTIONS.getReadyTransactions.REQUEST:
            case ACTIONS.getRejectedTransactions.REQUEST:
            case ACTIONS.getTransactions.REQUEST:
            case ACTIONS.getAllReadyTransactions.REQUEST:
            case ACTIONS.getAllRejectedTransactions.REQUEST:
            case ACTIONS.getTransactionsForUser.REQUEST: {
                state.transactions = []
                state.isLoading = true
                return
            }

            case ACTIONS.getBillingTransactions.REQUEST: {
                state.billing = []
                state.isLoading = true
                return
            }

            case ACTIONS.getTransaction.REQUEST: {
                state.transaction = null
                state.isLoading = true
                return
            }

            case ACTIONS.getReadyTransactions.SUCCESS:
            case ACTIONS.getRejectedTransactions.SUCCESS:
            case ACTIONS.getTransactions.SUCCESS:
            case ACTIONS.getTransactionsForUser.SUCCESS: {
                const { data } = action.payload
                state.isLoading = false
                if (data && data.transactions) {
                    state.transactions = [...data.transactions]
                }
                return
            }

            case ACTIONS.getAllReadyTransactions.SUCCESS:
            case ACTIONS.getAllRejectedTransactions.SUCCESS: {
                const { data } = action.payload
                state.isLoading = false
                state.transactions = [...data]
                return
            }

            case ACTIONS.getBillingTransactions.SUCCESS: {
                const { data } = action.payload
                state.isLoading = false
                state.billing = [...data]
                return
            }

            case ACTIONS.getTransaction.SUCCESS: {
                const { data } = action.payload
                state.transaction = {
                    ...data.transactions[0],
                }
                state.isLoading = false
                return
            }

            case ACTIONS.getReadyTransactions.FAILURE:
            case ACTIONS.getRejectedTransactions.FAILURE:
            case ACTIONS.getTransactions.FAILURE:
            case ACTIONS.getAllReadyTransactions.FAILURE:
            case ACTIONS.getAllRejectedTransactions.FAILURE:
            case ACTIONS.getBillingTransactions.FAILURE:
            case ACTIONS.getTransactionsForUser.FAILURE:
            case ACTIONS.getTransaction.FAILURE: {
                state.isLoading = false
                return
            }

            case ACTIONS.getTransactionCategories.REQUEST: {
                return
            }
            case ACTIONS.getTransactionCategories.SUCCESS: {
                const { data } = action.payload
                state.categories = [...data]
                return
            }
            case ACTIONS.getTransactionCategories.FAILURE: {
                return
            }

            default: {
                return
            }
        }
    })
