import { all, call, put, takeEvery } from 'redux-saga/effects'

import * as ACTIONS from './actions'
import { commonApi, usersApi } from '../../../custom/services/apis'
import history from '../../../services/history'
import { download } from '../../services/downloadFile'
import { getNameAndCompany } from '../auth/actions'

export function* updateAllowedCategoriesHandler(action) {
    const { userId, allowedCategories, postAction, postActionParams } = action.payload
    yield put(ACTIONS.updateAllowedCategories.request(action.payload))
    try {
        const data = yield call(commonApi.updateAllowedCategories, userId, allowedCategories)
        yield put(ACTIONS.updateAllowedCategories.success({ data }))
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.updateAllowedCategories.failure(e))
    }
}

export function* getSettingsHandler(action) {
    yield put(ACTIONS.getSettings.request(action.payload))
    try {
        const data = yield call(commonApi.getSettings)
        yield put(ACTIONS.getSettings.success({ data }))
    } catch (e) {
        yield put(ACTIONS.getSettings.failure(e))
    }
}

export function* updateOwnTransactionApprovalHandler(action) {
    const { body, postAction, postActionParams } = action.payload
    yield put(ACTIONS.updateOwnTransactionApproval.request(action.payload))
    try {
        const data = yield call(commonApi.updateOwnTransactionApproval, body)
        yield put(ACTIONS.updateOwnTransactionApproval.success({ data }))
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.updateOwnTransactionApproval.failure(e))
    }
}

export function* updateThresholdHandler(action) {
    const { body, postAction, postActionParams } = action.payload
    yield put(ACTIONS.updateThreshold.request(action.payload))
    try {
        const data = yield call(commonApi.updateThreshold, body)
        yield put(ACTIONS.updateThreshold.success({ data }))
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.updateThreshold.failure(e))
    }
}

export function* updateInitialCardLimitHandler(action) {
    const { body, postAction, postActionParams } = action.payload
    yield put(ACTIONS.updateInitialCardLimit.request(action.payload))
    try {
        const result = yield call(commonApi.updateInitialCardLimit, body)
        yield put(ACTIONS.updateInitialCardLimit.success(action.payload))
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.updateInitialCardLimit.failure(e))
    }
}

export function* openExternalUrlHandler(action) {
    const { url } = action.payload
    yield put(ACTIONS.openExternalUrl.request(action.payload))
    try {
        yield call({ context: window, fn: window.open }, url, '_blank')
        yield put(ACTIONS.openExternalUrl.success(action.payload))
    } catch (e) {
        yield put(ACTIONS.openExternalUrl.failure(e))
    }
}

export function* goBackHandler(action) {
    try {
        yield call({ context: history, fn: history.goBack })
        yield put(ACTIONS.goBack.success(action.payload))
    } catch (e) {
        yield put(ACTIONS.goBack.failure(action.payload))
    }
}

export function* updateReminderFrequencyHandler(action) {
    const { reminderFrequency, postAction, postActionParams } = action.payload
    yield put(ACTIONS.updateReminderFrequency.request(action.payload))
    try {
        yield call(commonApi.updateReminderFrequency, reminderFrequency)
        yield put(ACTIONS.updateReminderFrequency.success(action.payload))
        if (postAction) {
            yield put(postAction(postActionParams))
        }
    } catch (e) {
        yield put(ACTIONS.updateReminderFrequency.failure(e))
    }
}

export function* setCurrentAppHandler(action) {
    const { currentApp } = action.payload

    yield put(ACTIONS.setCurrentApp.success({ currentApp }))

}

export function* getRoleHandler(action) {
    yield put(ACTIONS.getRole.request(action.payload))
    try {
        const role = yield call(commonApi.getRole)
        yield put(ACTIONS.getRole.success({ ...role }))
    } catch (e) {
        yield put(ACTIONS.getRole.failure(e))
    }
}

export function* getUserListHandler(action) {

    yield put(ACTIONS.getUserList.request(action.payload))
    try {
        const userList = yield call(usersApi.getUserList)
        yield put(ACTIONS.getUserList.success(userList))
    } catch (e) {
        yield put(ACTIONS.getUserList.failure(e))
    }
}

export function* updateRoleStatusHandler(action) {
    const { userId, active } = action.payload
    yield put(ACTIONS.updateRoleStatus.request(action.payload))
    try {
        yield call(usersApi.updateRoleStatus, userId, active)
        yield put(ACTIONS.getUserList.trigger())
        yield put(ACTIONS.updateRoleStatus.success(action.payload))
    } catch (e) {
        yield put(ACTIONS.updateRoleStatus.failure(e))
    }
}

export function* updateRolePermissionHandler(action) {
    const { userId, values } = action.payload
    yield put(ACTIONS.updateRolePermission.request(action.payload))
    try {
        yield call(commonApi.updateRolePermission, userId, values)
        yield put(ACTIONS.getRole())
        yield put(getNameAndCompany())
        yield put(ACTIONS.updateRolePermission.success(action.payload))
        //yield call(notificationSuccess, appConstants.messages.updatedSuccessfully)
    } catch (e) {
        yield put(ACTIONS.updateRolePermission.failure(e))
    }
}

export function* getPrevUserAndAvatarHandler(action) {
    yield put(ACTIONS.getPrevUserAndAvatar.request(action.payload))
    try {
        const user = yield call(commonApi.getPrevUser)
        const avatar = yield call(commonApi.getPrevAvatar)
        yield put(ACTIONS.getPrevUserAndAvatar.success({ avatar, user }))
    } catch (e) {
        yield put(ACTIONS.getPrevUserAndAvatar.failure(e))
    }
}

export function* getDownloadFileHandler(action) {
    const { fileId } = action.payload
    yield put(ACTIONS.getDownloadFile.request(action.payload))
    try {
        const data = yield call(commonApi.getDownloadFile, fileId)
        yield call(download, data.file, data.filename)
        yield put(ACTIONS.getDownloadFile.success({ file }))
    } catch (e) {
        yield put(ACTIONS.getDownloadFile.failure(e))
    }
}

export function* getDownloadKybFileHandler(action) {
    const { fileId } = action.payload
    yield put(ACTIONS.getDownloadKybFile.request(action.payload))
    try {
        const data = yield call(commonApi.getDownloadKybFile, fileId)
        yield call(download, data.file, data.filename)
        yield put(ACTIONS.getDownloadKybFile.success({ file }))
    } catch (e) {
        yield put(ACTIONS.getDownloadKybFile.failure(e))
    }
}

/*export function* Handler(action) {
    const {} = action.payload
    yield put(ACTIONS.getTransactions.request(action.payload))
    try{
        yield put(ACTIONS.getTransactions.success(action.payload))
    }
    catch (e){
        yield put(ACTIONS.getTransactions.failure(e))
    }
}
*/

export function* watchCommonSaga() {
    yield all([
        takeEvery(ACTIONS.setCurrentApp.TRIGGER, setCurrentAppHandler),
        takeEvery(ACTIONS.updateAllowedCategories.TRIGGER, updateAllowedCategoriesHandler),
        takeEvery(ACTIONS.getSettings.TRIGGER, getSettingsHandler),
        takeEvery(ACTIONS.updateOwnTransactionApproval.TRIGGER, updateOwnTransactionApprovalHandler),
        takeEvery(ACTIONS.updateThreshold.TRIGGER, updateThresholdHandler),
        takeEvery(ACTIONS.updateInitialCardLimit.TRIGGER, updateInitialCardLimitHandler),
        takeEvery(ACTIONS.openExternalUrl.TRIGGER, openExternalUrlHandler),
        takeEvery(ACTIONS.goBack.TRIGGER, goBackHandler),
        takeEvery(ACTIONS.updateReminderFrequency.TRIGGER, updateReminderFrequencyHandler),
        takeEvery(ACTIONS.getRole.TRIGGER, getRoleHandler),
        takeEvery(ACTIONS.getUserList.TRIGGER, getUserListHandler),
        takeEvery(ACTIONS.updateRoleStatus.TRIGGER, updateRoleStatusHandler),
        takeEvery(ACTIONS.updateRolePermission.TRIGGER, updateRolePermissionHandler),
        takeEvery(ACTIONS.getPrevUserAndAvatar.TRIGGER, getPrevUserAndAvatarHandler),
        takeEvery(ACTIONS.getDownloadKybFile.TRIGGER, getDownloadKybFileHandler),
        takeEvery(ACTIONS.getDownloadFile.TRIGGER, getDownloadFileHandler),
    ])
}
