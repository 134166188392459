import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import SpinnerComponent from '../components/common/GlobalSpinner'
import FullPageLayout, { OutsideLayoutRoute } from '../components/layout/fullpageRoutes'
import appConstants from '../custom/config/appConstants'
import { useDispatch } from 'react-redux'
import { authGetUser } from '../custom/store/auth/actions'

const LazyLogin = lazy(() => import('../components/pages/Login'))
const LazyRegister = lazy(() => import('../components/pages/Register'))
const LazyForgotPassword = lazy(() => import('../components/pages/ForgotPassword'))
const LazyChangePassword = lazy(() => import('../components/pages/ChangePassword'))
//const LazyChangeMobile = lazy(() => import('../components/pages/ChangeMobile'))
const LazyDetailsRequired = lazy(() => import('../components/pages/DetailsRequired'))

const LazySmsVerification = lazy(() => import('../components/pages/smsVerification'))
//const LazySmsMobileVerification = lazy(() => import('../components/pages/smsMobileVerification'))
const LazyBusinessRoutes = lazy(() => import('../custom/routes/BusinessRoutes'))
const LazyPayrollRoutes = lazy(() => import('../custom/routes/PayrollRoutes'))
const LazyMain = lazy(() => import('../custom/appViews/MainView/MainView'))

const RootRoutes = () => {
    const dispatch = useDispatch()

    const isSignup = useRouteMatch(appConstants.routes.auth.register)

    useEffect(() => {
        if (!isSignup) {
            dispatch(authGetUser({ initLogin: true }))
        }
    }, [])

    return (
        // Set the directory path if you are deplying in sub-folder
        <Switch>
            {/* Dashboard Views */}

            {/** Full Page route /login */}
            <FullPageLayout
                exact
                path={appConstants.routes.auth.login}
                render={(matchprops) => (
                    <Suspense fallback={<SpinnerComponent />}>
                        <LazyLogin {...matchprops} />
                    </Suspense>
                )}
            />
            <FullPageLayout
                exact
                path={appConstants.routes.auth.smsVerification}
                render={(matchprops) => (
                    <Suspense fallback={<SpinnerComponent />}>
                        <LazySmsVerification {...matchprops} />
                    </Suspense>
                )}
            />
            {/*<FullPageLayout
                exact
                path={appConstants.routes.auth.smsMobileVerification}
                render={(matchprops) => (
                    <Suspense fallback={<SpinnerComponent/>}>
                        <LazySmsMobileVerification {...matchprops} />
                    </Suspense>
                )}
            />*/}
            <FullPageLayout
                exact
                path={appConstants.routes.auth.forgotPassword}
                render={(matchprops) => (
                    <Suspense fallback={<SpinnerComponent />}>
                        <LazyForgotPassword {...matchprops} />
                    </Suspense>
                )}
            />
            <FullPageLayout
                exact
                path={appConstants.routes.auth.changePassword}
                render={(matchprops) => (
                    <Suspense fallback={<SpinnerComponent />}>
                        <LazyChangePassword {...matchprops} />
                    </Suspense>
                )}
            />
            {/*<FullPageLayout
                exact
                path={appConstants.routes.auth.changeMobile}
                render={(matchprops) => (
                    <Suspense fallback={<SpinnerComponent/>}>
                        <LazyChangeMobile {...matchprops} />
                    </Suspense>
                )}
            />*/}
            <FullPageLayout
                exact
                path={appConstants.routes.auth.detailsRequired}
                render={(matchprops) => (
                    <Suspense fallback={<SpinnerComponent />}>
                        <LazyDetailsRequired {...matchprops} />
                    </Suspense>
                )}
            />
            <OutsideLayoutRoute
                exact
                path={appConstants.routes.auth.register}
                render={(matchprops) => (
                    <Suspense fallback={<SpinnerComponent />}>
                        <LazyRegister {...matchprops} />
                    </Suspense>
                )}
            />

            {/*App Routes*/}

            <Route path={appConstants.routes.business.index}>
                <Suspense fallback={<SpinnerComponent />}>
                    <LazyBusinessRoutes />
                </Suspense>
            </Route>
            <Route path={appConstants.routes.payroll.index}>
                <Suspense fallback={<SpinnerComponent />}>
                    <LazyPayrollRoutes />
                </Suspense>
            </Route>

            <Route exact path='/'>
                <Suspense fallback={<SpinnerComponent />}>
                    <LazyMain />
                </Suspense>
            </Route>
        </Switch>
    )
}

export default RootRoutes
