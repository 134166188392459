import { createSelector } from 'reselect'
import get from 'lodash/get'
import appConstants from '../../../../custom/config/appConstants'
import uuid from 'react-uuid'
import { formatAmount, formatData, formatDataShort, formatMonthYear } from '../../common/selectors'

export const getPaymentState = createSelector(
    [
        (state) => {
            return get(state, appConstants.store.routes.payments)
        },
    ],
    (state) => state,
)

export const getPaymentIsFetching = createSelector([getPaymentState], (state) => {
    try {
        return state.isFetching
    } catch (e) {
    }
    return false

})

export const getPayments = createSelector([getPaymentState, (state, paymentKey) => paymentKey], (state, paymentKey) => {
    try {
        return state[paymentKey]
    } catch (e) {
    }

    return null
})

export const getPaymentsPage = createSelector([getPayments], (state) => {
    try {
        return state.page ? state.page : null
    } catch (e) {
    }

    return null
})
export const getPaymentsSearch = createSelector([getPayments], (state) => {
    try {
        return state.search ? state.search : null
    } catch (e) {
    }

    return null
})
export const getPaymentsNumOfPages = createSelector([getPayments], (state) => {
    try {
        return state.numOfPages ? state.numOfPages : 1
    } catch (e) {
    }

    return 1
})
export const getPaymentsTransactions = createSelector([getPayments], (state) => {

    try {
        return state.transactions ? state.transactions : []
    } catch (e) {
    }
    return []
})

export const getAuthBulkPaymentTransactionsF = createSelector([getPaymentsTransactions], (transactions) => {
    try {
        if (transactions) {
            return transactions.map((item) => {
                return {
                    ...item,
                    paymentId: item._id.toUpperCase(),
                    download: '',
                    approve: '',
                    reject: '',
                    rowId: uuid(),

                    amount: formatAmount(item.amount),
                    userFileName: item.userFileName || 'N/A',
                    uploadedAt: formatData(item.uploadedAt) || 'N/A',
                    statusUpdatedAt: formatData(item.statusUpdatedAt) || 'N/A',
                    scheduledFor: formatData(item.scheduledFor) || 'N/A',
                    status: item.status || 'N/A',
                }
            })
        }
        return []
    } catch (e) {
    }
    return []
})

export const getExternalAuthWpsPaymentTransactionsF = createSelector([getPaymentsTransactions], (transactions) => {
    try {
        if (transactions) {
            return transactions.map((item) => {
                return {
                    ...item,
                    paymentId: item._id.toUpperCase(),
                    download: '',
                    approve: '',
                    reject: '',
                    rowId: uuid(),

                    amount: formatAmount(item.amount),
                    userFileName: item.userFileName || 'N/A',
                    uploadedAt: formatData(item.uploadedAt) || 'N/A',
                    statusUpdatedAt: formatData(item.statusUpdatedAt) || 'N/A',
                    scheduledFor: formatData(item.scheduledFor) || 'N/A',
                    status: item.status || 'N/A',
                }
            })
        }
        return []
    } catch (e) {
    }
    return []
})

export const getAuthIndividualPaymentTransactionsF = createSelector([getPaymentsTransactions], (transactions) => {
    try {
        if (transactions) {
            return transactions.map((item) => {
                return {
                    ...item,
                    individualSalaryFileEid: item.individualSalaryFileEid || 'N/A',
                    download: '',
                    approve: '',
                    reject: '',
                    rowId: uuid(),
                    fileType: item.fileType || 'N/A',
                    amount: formatAmount(item.amount),
                    userFileName: item.userFileName || 'N/A',
                    uploadedAt: formatData(item.uploadedAt) || 'N/A',
                    statusUpdatedAt: formatData(item.statusUpdatedAt) || 'N/A',
                    scheduledFor: formatData(item.scheduledFor) || 'N/A',
                    status: item.status || 'N/A',
                }
            })
        }
        return []
    } catch (e) {
    }
    return []
})

export const getAuthWpsPaymentTransactionsF = createSelector([getPaymentsTransactions], (transactions) => {
    try {
        if (transactions) {
            return transactions.map((item) => {
                return {
                    ...item,
                    paymentId: item._id.toUpperCase(),
                    download: '',
                    approve: '',
                    reject: '',
                    rowId: uuid(),

                    amount: formatAmount(item.amount),
                    userFileName: item.userFileName || 'N/A',
                    uploadedAt: formatData(item.uploadedAt) || 'N/A',
                    statusUpdatedAt: formatData(item.statusUpdatedAt) || 'N/A',
                    scheduledFor: formatData(item.scheduledFor) || 'N/A',
                    status: item.status || 'N/A',
                }
            })
        }
        return []
    } catch (e) {
    }
    return []
})


export const getValidateBulkPaymentTransactionsF = createSelector([getPaymentsTransactions], (transactions) => {
    try {
        if (transactions) {
            return transactions.map((item) => {
                return {
                    ...item,
                    paymentId: item._id.toUpperCase(),
                    download: '',
                    approve: '',
                    reject: '',
                    rowId: uuid(),
                    amount: formatAmount(item.amount),
                    userFileName: item.userFileName || 'N/A',
                    uploadedAt: formatData(item.uploadedAt) || 'N/A',
                    statusUpdatedAt: formatData(item.statusUpdatedAt) || 'N/A',
                    scheduledFor: formatData(item.scheduledFor) || 'N/A',
                    status: item.status || 'N/A',
                }
            })
        }
        return []
    } catch (e) {
    }
    return []
})

export const getExternalValidateWpsPaymentTransactionsF = createSelector([getPaymentsTransactions], (transactions) => {
    try {
        if (transactions) {
            return transactions.map((item) => {
                return {
                    ...item,
                    paymentId: item._id.toUpperCase(),
                    download: '',
                    approve: '',
                    reject: '',
                    rowId: uuid(),
                    amount: formatAmount(item.amount),
                    userFileName: item.userFileName || 'N/A',
                    uploadedAt: formatData(item.uploadedAt) || 'N/A',
                    statusUpdatedAt: formatData(item.statusUpdatedAt) || 'N/A',
                    scheduledFor: formatData(item.scheduledFor) || 'N/A',
                    status: item.status || 'N/A',
                }
            })
        }
        return []
    } catch (e) {
    }
    return []
})

export const getValidateIndividualPaymentTransactionsF = createSelector([getPaymentsTransactions], (transactions) => {
    try {
        if (transactions) {
            return transactions.map((item) => {
                return {
                    ...item,
                    individualSalaryFileEid: item.individualSalaryFileEid || 'N/A',
                    download: '',
                    approve: '',
                    reject: '',
                    rowId: uuid(),
                    fileType: item.fileType || 'N/A',
                    amount: formatAmount(item.amount),
                    userFileName: item.userFileName || 'N/A',
                    uploadedAt: formatData(item.uploadedAt) || 'N/A',
                    statusUpdatedAt: formatData(item.statusUpdatedAt) || 'N/A',
                    scheduledFor: formatData(item.scheduledFor) || 'N/A',
                    status: item.status || 'N/A',
                }
            })
        }
        return []
    } catch (e) {
    }
    return []
})

export const getValidateWpsPaymentTransactionsF = createSelector([getPaymentsTransactions], (transactions) => {
    try {
        if (transactions) {
            return transactions.map((item) => {
                return {
                    ...item,
                    paymentId: item._id.toUpperCase(),
                    download: '',
                    approve: '',
                    reject: '',
                    rowId: uuid(),

                    amount: formatAmount(item.amount),
                    userFileName: item.userFileName || 'N/A',
                    uploadedAt: formatData(item.uploadedAt) || 'N/A',
                    statusUpdatedAt: formatData(item.statusUpdatedAt) || 'N/A',
                    scheduledFor: formatData(item.scheduledFor) || 'N/A',
                    status: item.status || 'N/A',
                }
            })
        }
        return []
    } catch (e) {
    }
    return []
})

export const getWpsStatusTransactionsF = createSelector([getPaymentsTransactions], (transactions) => {
    try {
        if (transactions) {
            return transactions.map((item) => {
                return {
                    ...item,
                    actions: '',
                    rowId: uuid(),
                    paymentId: item._id || 'N/A',
                    amount: formatAmount(item.amount),
                    fees: item.fees || 'N/A',
                    status: item.status || 'N/A',
                    statusUpdatedAt: formatData(item.statusUpdatedAt),
                    uploadedAt: formatData(item.uploadedAt),
                }
            })
        }
        return []
    } catch (e) {
    }
    return []
})

export const getStatusReportTransactionsF = createSelector([getPaymentsTransactions], (transactions) => {
    try {
        if (transactions) {
            return transactions.map((item) => {
                return {
                    ...item,
                    file: '',
                    rowId: uuid(),
                    transactionType: item.transactionType || 'N/A',
                    amount: formatAmount(item.amount),
                    fees: item.fees || 'N/A',
                    userFileName: item.userFileName || 'N/A',
                    uploadedAt: formatData(item.uploadedAt) || 'N/A',
                    statusUpdatedAt: formatData(item.statusUpdatedAt) || 'N/A',
                    lastActionBy: item.lastActionBy || 'N/A',
                    status: item.status || 'N/A',
                }
            })
        }
        return []
    } catch (e) {
    }
    return []
})

export const getViewPaymentsTransactionsF = createSelector([getPaymentsTransactions], (transactions) => {
    try {
        if (transactions) {
            return transactions.map((item) => {
                return {
                    ...item,
                    actions: '',
                    rowId: uuid(),
                    employeeName: item.employeeName || '-',
                    idCardNumber: item.idCardNumber,
                    vamIban: item.vamIban || '-',
                    iban: item.iban || '-',
                    paymentType: item.paymentType?.replace("_", " ") || '***',
                    description: item.description || '-',
                    createdAt: formatDataShort({ createdAt: item.createdAt }) || '-',
                    salaryMonth: item.salaryMonth ? formatMonthYear(item.salaryMonth) : '-',
                    salary: item.salary || '-',
                    status: item.paid === true ? 'Received' : (item.registered === true) ? 'Could not Load Funds' : 'Account not active/connected',
                    paymentId: item._id.toUpperCase() || '-',

                }
            })
        }
        return []
    } catch (e) {
    }
    return []
})

export const getTransactionsBalance = createSelector([getPaymentState], (state) => {
    try {
        return formatAmount(state.balance ? state.balance : '0.00', true)
    } catch (e) {
    }
    return '0'

})

export const getPaymentSearchText = createSelector([getPaymentState], (state) => {
    try {
        return state.ViewPayments.text
    } catch (e) {
    }
    return null

})
