import { createActionsCreator } from '../actionCreatorsFactory'

const createAction = createActionsCreator('SAGAS/AUTH')

export const authSignUp = createAction('SIGN_UP')
export const authLogIn = createAction('LOG_IN')
export const authLogOut = createAction('LOG_OUT')
export const smsVerification = createAction('SMS_VERIFICATION')
export const getNameAndCompany = createAction('GET_NAME_AND_COMPANY')
export const authResetPassword = createAction('RESET_PASSWORD')
export const authUpdateMobile = createAction('UPDATE_MOBILE')
export const authVerifyMobile = createAction('VERIFY_MOBILE')
export const authRestorePassword = createAction('RESTORE_PASSWORD')
export const authSmsForRestorePassword = createAction('SMS_FOR_RESTORE_PASSWORD')
export const enforcePasswordReset = createAction('ENFORCE_PASSWORD_RESET')
export const authGetUser = createAction('GET_USER')
export const authGetUserAvatar = createAction('GET_USER_AVATAR')
export const authFillDetailsData = createAction('FILL_DETAILS_DATA')

