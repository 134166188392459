import produce from 'immer'
import * as ACTIONS from './actions'

export const initialState = {
    isFetching: false,
    isLoggedIn: true,
    user: null,
    userAvatar: false,
    loginErrors: {},
}

/** @reducer */
export default (initState = { ...initialState }, action = {}) =>
    produce(initState, (state) => {
        switch (action.type) {
            case ACTIONS.authSignUp.SUCCESS: {
                const { token } = action.payload
                state.isLoggedIn = true
                return
            }

            case ACTIONS.authLogOut.SUCCESS: {
                state.isLoggedIn = false
                return
            }
            case ACTIONS.authLogIn.SUCCESS: {
                state.isLoggedIn = true
                return
            }
            case ACTIONS.smsVerification.SUCCESS: {
                state.isLoggedIn = true
                return
            }
            case ACTIONS.getNameAndCompany.SUCCESS: {
                const { companyTradingName, fullName, role } = action.payload
                state.user = {
                    fullName,
                    companyTradingName,
                    role: { ...role },
                }
                return
            }
            case ACTIONS.authGetUser.SUCCESS: {
                const { user } = action.payload
                return {
                    ...state,
                    user: { ...user },
                    isLoggedIn: true,
                }
            }


            case ACTIONS.authGetUser.FAILURE: {
                state.user = null
                state.isLoggedIn = false
                return
            }


            case ACTIONS.authGetUserAvatar.SUCCESS: {
                const { avatar } = action.payload
                state.userAvatar = avatar
                return
            }
            case ACTIONS.authGetUserAvatar.FAILURE: {
                state.userAvatar = false
                return
            }

            case ACTIONS.getNameAndCompany.FAILURE: {
                state.user = null
                return
            }

            case ACTIONS.enforcePasswordReset.SUCCESS: {
                const { enforcePasswordReset } = action.payload
                state.enforcePasswordReset = enforcePasswordReset
                return
            }


            default: {
                return
            }
        }
    })
