import { createActionsCreator } from '../actionCreatorsFactory'

const createAction = createActionsCreator('SAGAS/USERS')

export const getUsers = createAction('GET_USERS')
export const addUser = createAction('ADD_USER')
export const updateRole = createAction('UPDATE_ROLE')
export const uploadNewUsersFile = createAction('UPLOAD_NEW_USER_FILE')
export const addUserDetails = createAction('ADD_USER_DETAILS')
export const getUserDetails = createAction('GET_USER_DETAILS')
export const deleteUser = createAction('DELETE_USER')
export const getPayrollEmployees = createAction('GET_PAYROLL_EMPLOYEES')
export const getPrePrintedPayrollEmployees = createAction('GET_PREPRINTED_PAYROLL_EMPLOYEES')
export const removePayrollEmployee = createAction('REMOVE_PAYROLL_EMPLOYEE')
export const getFileCounts = createAction('GET_FILE_COUNTS')
export const registerUser = createAction('REGISTER_USER')
export const getUserPromo = createAction('GET_USER_PROMO')
