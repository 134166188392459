import React from 'react'
import templateConfig from '../../utility/templateConfig'
import classnames from 'classnames'
import css from './FullpageLayout.module.scss'
import { NowMoneyLogoLight } from '../../custom/appComponents/NowMoneyLogo'

const FullPageLayout = ({ children, ...rest }) => {
    return (
        <div
            className={classnames('login-layout wrapper ', css.FullPageLayout, {
                [templateConfig.layoutColor]: !!templateConfig?.layoutColor,
            })}
        >
            <NowMoneyLogoLight className={css.NowMoneyLogo} />
            <main className='main'>{children}</main>
        </div>
    )
}

export default FullPageLayout
