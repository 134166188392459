import produce from 'immer'
import * as ACTIONS from './actions'

export const initialState = {
    isFetching: false,
    AuthBulkPayments: null,
    AuthExternalWpsPayments: null,
    AuthIndividualPayments: null,
    AuthWpsPayments: null,
    ValidateBulkPayment: null,
    ExternalValidateWpsPayments: null,
    ValidateIndividualPayment: null,
    ValidateWpsPayments: null,
    WpsStatus: null,
    StatusRepost: null,
    ViewPayments: null,
    balance: 0,
}

/** @reducer */
export default (initState = { ...initialState }, action = {}) =>
    produce(initState, (state) => {
        switch (action.type) {
            case ACTIONS.getAuthBulkPayments.REQUEST: {
                state.isFetching = true
                //state.AuthBulkPayments = null
                return
            }
            case ACTIONS.getAuthBulkPayments.SUCCESS: {
                const { data, page, search } = action.payload
                state.isFetching = false
                state.AuthBulkPayments = { ...data, page, search }
                return
            }
            case ACTIONS.getAuthBulkPayments.FAILED: {
                state.isFetching = false
                return
            }
            case ACTIONS.getAuthExternalWpsPayments.REQUEST: {
                state.isFetching = true
                //state.AuthExternalWpsPayments = null
                return
            }
            case ACTIONS.getAuthExternalWpsPayments.SUCCESS: {
                const { data, page, search } = action.payload
                state.isFetching = false
                state.AuthExternalWpsPayments = { ...data, page, search }
                return
            }
            case ACTIONS.getAuthExternalWpsPayments.FAILED: {
                state.isFetching = false
                return
            }
            case ACTIONS.getAuthIndividualPayments.REQUEST: {
                state.isFetching = true
                //state.AuthIndividualPayments = null
                return
            }
            case ACTIONS.getAuthIndividualPayments.SUCCESS: {
                const { data, page, search } = action.payload
                state.isFetching = false
                state.AuthIndividualPayments = { ...data, page, search }
                return
            }
            case ACTIONS.getAuthIndividualPayments.FAILED: {
                state.isFetching = false
                return
            }

            case ACTIONS.getAuthWpsPayments.REQUEST: {
                state.isFetching = true
                //state.AuthWpsPayments = null
                return
            }
            case ACTIONS.getAuthWpsPayments.SUCCESS: {
                const { data, page, search } = action.payload
                state.isFetching = false
                state.AuthWpsPayments = { ...data, page, search }
                return
            }
            case ACTIONS.getAuthWpsPayments.FAILED: {
                state.isFetching = false
                return
            }

            case ACTIONS.getValidateBulkPayment.REQUEST: {
                state.isFetching = true
                //state.ValidateBulkPayment = null
                return
            }
            case ACTIONS.getValidateBulkPayment.SUCCESS: {
                const { data, page, search } = action.payload
                state.isFetching = false
                state.ValidateBulkPayment = { ...data, page, search }
                return
            }
            case ACTIONS.getValidateBulkPayment.FAILED: {
                state.isFetching = false
                return
            }

            case ACTIONS.getExternalValidateWpsPayments.REQUEST: {
                state.isFetching = true
                //state.ExternalValidateWpsPayments = null
                return
            }
            case ACTIONS.getExternalValidateWpsPayments.SUCCESS: {
                const { data, page, search } = action.payload
                state.isFetching = false
                state.ExternalValidateWpsPayments = { ...data, page, search }
                return
            }
            case ACTIONS.getExternalValidateWpsPayments.FAILED: {
                state.isFetching = false
                return
            }

            case ACTIONS.getValidateIndividualPayment.REQUEST: {
                state.isFetching = true
                //state.ValidateIndividualPayment = null
                return
            }
            case ACTIONS.getValidateIndividualPayment.SUCCESS: {
                const { data, page, search } = action.payload
                state.isFetching = false
                state.ValidateIndividualPayment = { ...data, page, search }
                return
            }
            case ACTIONS.getValidateIndividualPayment.FAILED: {
                state.isFetching = false
                return
            }

            case ACTIONS.getValidateWpsPayments.REQUEST: {
                state.isFetching = true
                //state.ValidateWpsPayments = null
                return
            }
            case ACTIONS.getValidateWpsPayments.SUCCESS: {
                const { data, page, search } = action.payload
                state.isFetching = false
                state.ValidateWpsPayments = { ...data, page, search }
                return
            }
            case ACTIONS.getValidateWpsPayments.FAILED: {
                state.isFetching = false
                return
            }
            case ACTIONS.getWpsStatus.REQUEST: {
                state.isFetching = true
                //state.ValidateWpsPayments = null
                return
            }
            case ACTIONS.getWpsStatus.SUCCESS: {
                const { data, page, search } = action.payload
                state.isFetching = false
                state.WpsStatus = { ...data, page, search }
                return
            }
            case ACTIONS.getWpsStatus.FAILED: {
                state.isFetching = false
                return
            }
            case ACTIONS.getStatusReport.REQUEST: {
                state.isFetching = true
                //state.ValidateWpsPayments = null
                return
            }
            case ACTIONS.getStatusReport.SUCCESS: {
                const { data, page, search } = action.payload
                state.isFetching = false
                state.StatusRepost = { ...data, page, search }
                return
            }
            case ACTIONS.getStatusReport.FAILED: {
                state.isFetching = false
                return
            }
            case ACTIONS.getViewPayments.REQUEST: {
                state.isFetching = true
                //state.ValidateWpsPayments = null
                return
            }
            case ACTIONS.getViewPayments.SUCCESS: {
                const { data, page, search, text } = action.payload
                state.isFetching = false
                state.ViewPayments = { ...data, page, search, text }
                return
            }
            case ACTIONS.getViewPayments.FAILED: {
                state.isFetching = false
                return
            }
            case ACTIONS.clearData.TRIGGER: {
                const { paymentKey } = action.payload
                if (state[paymentKey]) {
                    state[paymentKey] = null
                }
                return
            }
            /*case ACTIONS.approveAuthIndividualPaymentSmsCode.SUCCESS: {
                const { data } = action.payload
                state.authIndividualPayment = data
                return
            }*/

            case ACTIONS.approveAuthBulkPayment.REQUEST:
            case ACTIONS.generateSMSMultipleAuthBulkPayment.REQUEST:
            case ACTIONS.rejectAuthorizePayment.REQUEST:
            case ACTIONS.rejectExternalAuthWPSPayment.REQUEST:
            case ACTIONS.approveAuthIndividualPayment.REQUEST:
            case ACTIONS.rejectAuthorizeIndividualPayment.REQUEST:
            case ACTIONS.approveAuthWPSPayment.REQUEST:
            case ACTIONS.rejectAuthWPSPayment.REQUEST:
            case ACTIONS.validateValidBulkPayment.REQUEST:
            case ACTIONS.rejectValidateBulkPayment.REQUEST:
            case ACTIONS.validExternalValidateWpsBulk.REQUEST:
            case ACTIONS.rejectExternalValidateWpsBulk.REQUEST:
            case ACTIONS.validValidateIndividualPayment.REQUEST:
            case ACTIONS.rejectValidateIndividualPayment.REQUEST:
            case ACTIONS.approveMultpleWithSmsCodeBulk.REQUEST:
            case ACTIONS.validValidateWpsBulk.REQUEST:
            case ACTIONS.rejectValidateWpsBulk.REQUEST: {
                state.isFetching = true
                return
            }
            case ACTIONS.approveAuthBulkPayment.SUCCESS:
            case ACTIONS.generateSMSMultipleAuthBulkPayment.SUCCESS:
            case ACTIONS.rejectAuthorizePayment.SUCCESS:
            case ACTIONS.rejectExternalAuthWPSPayment.SUCCESS:
            case ACTIONS.approveAuthIndividualPayment.SUCCESS:
            case ACTIONS.rejectAuthorizeIndividualPayment.SUCCESS:
            case ACTIONS.approveAuthWPSPayment.SUCCESS:
            case ACTIONS.rejectAuthWPSPayment.SUCCESS:
            case ACTIONS.validateValidBulkPayment.SUCCESS:
            case ACTIONS.rejectValidateBulkPayment.SUCCESS:
            case ACTIONS.validExternalValidateWpsBulk.SUCCESS:
            case ACTIONS.rejectExternalValidateWpsBulk.SUCCESS:
            case ACTIONS.validValidateIndividualPayment.SUCCESS:
            case ACTIONS.rejectValidateIndividualPayment.SUCCESS:
            case ACTIONS.approveMultpleWithSmsCodeBulk.SUCCESS:
            case ACTIONS.validValidateWpsBulk.SUCCESS:
            case ACTIONS.rejectValidateWpsBulk.SUCCESS: {
                state.isFetching = false
                return
            }
            case ACTIONS.approveAuthBulkPayment.FAILED:
            case ACTIONS.generateSMSMultipleAuthBulkPayment.FAILED:
            case ACTIONS.rejectAuthorizePayment.FAILED:
            case ACTIONS.rejectExternalAuthWPSPayment.FAILED:
            case ACTIONS.approveAuthIndividualPayment.FAILED:
            case ACTIONS.rejectAuthorizeIndividualPayment.FAILED:
            case ACTIONS.approveAuthWPSPayment.FAILED:
            case ACTIONS.rejectAuthWPSPayment.FAILED:
            case ACTIONS.validateValidBulkPayment.FAILED:
            case ACTIONS.rejectValidateBulkPayment.FAILED:
            case ACTIONS.validExternalValidateWpsBulk.FAILED:
            case ACTIONS.rejectExternalValidateWpsBulk.FAILED:
            case ACTIONS.validValidateIndividualPayment.FAILED:
            case ACTIONS.rejectValidateIndividualPayment.FAILED:
            case ACTIONS.validValidateWpsBulk.FAILED:
            case ACTIONS.approveMultpleWithSmsCodeBulk.FAILED:
            case ACTIONS.rejectValidateWpsBulk.FAILED: {
                state.isFetching = false
                return
            }

            case ACTIONS.getBalance.SUCCESS: {
                const { data } = action.payload
                state.balance = data
                return
            }

            default: {
                return
            }
        }
    })

