function pad2(number) {
    return (number < 10 ? '0' : '') + number
}

export const getReadableDate = (value) => {
    if (value) {
        const uaeTime = new Date(value).toLocaleString('en-US', { timeZone: 'Asia/Dubai' })
        let dateType = new Date(uaeTime)
        return (
            dateType.getDate() +
            '/' +
            (dateType.getMonth() + 1) +
            '/' +
            dateType.getFullYear() +
            ' ' +
            dateType.getHours() +
            ':' +
            pad2(dateType.getMinutes())
        )
    } else {
        return value
    }
}

export const getDataUrlFromBlob = (data) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = function() {
            resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(data)
    })

}

export const getUserAvatar = (data) => {
    return getDataUrlFromBlob(data)
}

export const getSteps = (chartData) => {
    if (chartData) {
        const allValues = []
        chartData.series.forEach((ser) => {
            allValues.push(...ser)
        })
        const min = Math.min(...allValues)
        const max = Math.max(...allValues)
        const result = Math.ceil(Math.abs(max - min) / 3)
        return result ? result : 1
    }
    return 1
}

export const capitalizeString = (text) => {
    if (text) {
        return text.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
    }
    return ''
}
