import React from 'react'

const NowMoneyLogoLight = (props) => {
    const { className } = props
    return (
        <div className={className}>
            <svg width='163' height='82' viewBox='0 0 163 82' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M40.9091 82C63.5026 82 81.8182 63.6437 81.8182 41C81.8182 18.3563 63.5026 0 40.9091 0C18.3156 0 0 18.3563 0 41C0 63.6437 18.3156 82 40.9091 82Z'
                    fill='#FFF9F2' />
                <path
                    d='M20.9091 26.4219C19.9091 26.4219 19 27.2419 19 28.3352V41.0908C19 49.1996 25.5455 55.6685 33.5455 55.6685V44.6441V40.9997V26.4219H20.9091Z'
                    fill='#5F69FF' />
                <path
                    d='M48.1822 26.4219H33.6367C41.7276 26.4219 48.1822 32.9819 48.1822 40.9997C48.1822 49.0174 54.7276 55.5774 62.7276 55.5774V40.9997C62.8185 32.8908 56.2731 26.4219 48.1822 26.4219Z'
                    fill='#00DB70' />
                <path
                    d='M33.6367 26.4219V40.9997C33.6367 49.1085 40.1822 55.5774 48.1822 55.5774V44.6441V40.9997C48.1822 32.8908 41.6367 26.4219 33.6367 26.4219Z'
                    fill='#FFB400' />
                <path
                    d='M115.908 40.9072V23.6872C115.908 23.2316 115.545 22.8672 115.09 22.8672H111.636C111.181 22.8672 110.817 23.2316 110.817 23.6872V33.2539L102.454 23.1405C102.272 22.9583 102.09 22.8672 101.817 22.8672H98.5447C98.0902 22.8672 97.7266 23.2316 97.7266 23.6872V40.9983C97.7266 41.4539 98.0902 41.8183 98.5447 41.8183H101.999C102.454 41.8183 102.817 41.4539 102.817 40.9983V31.4316L111.181 41.545C111.363 41.7272 111.545 41.8183 111.817 41.8183H115.09C115.454 41.8183 115.908 41.4539 115.908 40.9072Z'
                    fill='white' />
                <path
                    d='M128.271 22.5938C122.817 22.5938 118.635 26.7849 118.635 32.3426C118.635 37.8093 122.817 42.0004 128.271 42.0004C133.817 42.0004 137.998 37.8093 137.998 32.3426C137.907 26.7849 133.817 22.5938 128.271 22.5938ZM128.271 36.9893C125.635 36.9893 123.817 34.8938 123.817 32.3426C123.817 29.7004 125.635 27.6049 128.271 27.6049C130.907 27.6049 132.726 29.6093 132.726 32.3426C132.726 34.8938 130.907 36.9893 128.271 36.9893Z'
                    fill='white' />
                <path
                    d='M162.091 22.5938H158.818C158.363 22.5938 158 22.9582 158 23.4138L157.182 34.5293L153.545 26.6937C153.363 26.4204 153.091 26.2382 152.818 26.2382H151.273C150.909 26.2382 150.636 26.4204 150.545 26.6937L146.909 34.5293L146.091 23.4138C146.091 22.9582 145.727 22.5938 145.273 22.5938H142C141.545 22.5938 141.091 23.0493 141.182 23.5049L142.454 40.9071C142.454 41.3626 142.818 41.7271 143.273 41.7271H147.636C148 41.7271 148.273 41.5449 148.363 41.2715L152.091 33.2538L155.818 41.2715C156 41.5449 156.273 41.7271 156.545 41.7271H160.909C161.363 41.7271 161.727 41.3626 161.727 40.9071L163 23.5049C163 23.0493 162.636 22.5938 162.091 22.5938Z'
                    fill='white' />
                <path
                    d='M109.182 46.1914C111.546 46.1914 112.91 47.7403 112.91 50.2914V56.5781H111.364V50.6558C111.364 48.6514 110.364 47.5581 108.728 47.5581C107.182 47.5581 105.91 48.7425 105.91 50.747V56.5781H104.455V50.5647C104.455 48.6514 103.455 47.5581 101.819 47.5581C100.182 47.5581 99.0005 48.9247 99.0005 50.747V56.5781H97.4551V46.3736H99.0005V48.1047C99.6369 47.1025 100.546 46.1914 102.273 46.1914C103.91 46.1914 105.001 47.1025 105.546 48.1959C106.364 47.1025 107.364 46.1914 109.182 46.1914Z'
                    fill='white' />
                <path
                    d='M120.273 56.7603C117.273 56.7603 115 54.3914 115 51.4759C115 48.5603 117.273 46.1914 120.273 46.1914C123.273 46.1914 125.545 48.5603 125.545 51.4759C125.636 54.3003 123.364 56.7603 120.273 56.7603ZM120.273 47.467C118.091 47.467 116.545 49.1981 116.545 51.3847C116.545 53.5714 118.182 55.3936 120.273 55.3936C122.455 55.3936 124 53.6625 124 51.4759C124.091 49.2892 122.455 47.467 120.273 47.467Z'
                    fill='white' />
                <path
                    d='M129.727 56.5793H128.182V46.3749H129.727V48.106C130.363 47.0127 131.454 46.1016 133.182 46.1016C135.636 46.1016 137.091 47.7416 137.091 50.2016V56.4882H135.545V50.566C135.545 48.6527 134.545 47.4682 132.727 47.4682C131 47.4682 129.727 48.7438 129.727 50.6571V56.5793Z'
                    fill='white' />
                <path
                    d='M144.545 55.4847C145.909 55.4847 146.909 54.9381 147.727 54.027L148.636 54.847C147.636 56.0314 146.363 56.7603 144.454 56.7603C141.636 56.7603 139.363 54.5736 139.363 51.4759C139.363 48.5603 141.454 46.1914 144.272 46.1914C147.272 46.1914 149 48.5603 149 51.567C149 51.7492 149 51.8403 149 52.0225H141C141.272 54.2092 142.818 55.4847 144.545 55.4847ZM147.545 50.9292C147.363 49.107 146.363 47.467 144.272 47.467C142.545 47.467 141.181 48.9247 141 50.9292H147.545Z'
                    fill='white' />
                <path
                    d='M156.545 56.8528C155.727 58.9483 154.727 59.7683 153.181 59.7683C152.363 59.7683 151.727 59.5861 150.999 59.2217L151.545 58.0372C151.999 58.3106 152.454 58.4017 153.09 58.4017C153.999 58.4017 154.545 57.9461 155.181 56.4883L150.545 46.375H152.181L155.909 54.8483L159.181 46.375H160.818L156.545 56.8528Z'
                    fill='white' />
            </svg>
        </div>
    )
}

export default NowMoneyLogoLight
