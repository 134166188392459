// import external modules
import React from 'react'
import { Route } from 'react-router-dom'
import FullPageLayout from './fullpageLayout'
import OutsideLayout from './OutsideLayout'

export const FullPageLayoutRoute = ({ render, ...rest }) => {
    return <Route {...rest} render={(matchProps) => <FullPageLayout>{render(matchProps)}</FullPageLayout>} />
}
export const OutsideLayoutRoute = ({ render, ...rest }) => {
    return <Route {...rest} render={(matchProps) => <OutsideLayout>{render(matchProps)}</OutsideLayout>} />
}

export default FullPageLayoutRoute
