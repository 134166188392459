const devSettings = {
    apiUrl: 'http://localhost:3000/api/v1',
    serverUrl: 'http://localhost:3000',
}

const prodSettings = {
    apiUrl: `https://employer.nowmoney.me/api/v1`,
    serverUrl: `https://employer.nowmoney.me`,
}

const Settings = process.env.NODE_ENV === 'production' ? prodSettings : devSettings

export const getApiUrl = () => {
    return Settings.apiUrl
}
export const getServerUrl = () => {
    return Settings.serverUrl
}

export default {
    getApiUrl,
    getServerUrl,
}
