import { toast } from 'react-toastify'
import queue from 'async/queue'


toast.configure({
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    //same as ToastContainer props
})

const MSG_TYPES = {
    error: 'error',
    warning: 'warn',
    info: 'info',
    success: 'success',
}

const messagesActive = new Map()

function onClose(props) {
    const { toastId, children } = this

    if (messagesActive.has(children) && messagesActive.get(children) === toastId) {
        messagesActive.delete(children)
    }
}

const sendMessage = (message, type = MSG_TYPES.success, options = {}) => {

    if (messagesActive.has(message)) {
        const toastId = messagesActive.get(message)
        if (toast.isActive(toastId)) {
            return null
        }
    }
    let _toastId
    switch (type) {
        case MSG_TYPES.error: {
            _toastId = toast.error(message, {
                onClose,
                ...options,
            })
            break
        }
        case MSG_TYPES.warning: {
            _toastId = toast.warn(message, {
                onClose,
                ...options,
            })
            break
        }
        case MSG_TYPES.info: {
            _toastId = toast.info(message, {
                onClose,
                ...options,
            })
            break
        }
        case MSG_TYPES.success: {
            _toastId = toast.success(message, {
                onClose,
                ...options,
            })
            break
        }
        default: {
            _toastId = toast.success(message, {
                onClose,
                ...options,
            })
            break
        }
    }
    messagesActive.set(message, _toastId)
}

const q = queue(function(task, callback) {
    sendMessage(task.message, task.type, task.options)
    callback()
}, 1)

const _error = (error, options) => {
    q.push({ message: error, type: MSG_TYPES.error, options })
    //return sendMessage(error, MSG_TYPES.error, options)
}

export const notificationError = (error, options) => {
    if (!error.response) {
        return _error(error.message || error, options)
    }
    if (error.response && error.response.status < 500) {
        return _error(error.response.data, options)
    }
}

export const notificationWarning = (message, options) => {
    return sendMessage(message, MSG_TYPES.warning, options)
}

export const notificationInfo = (message, options) => {
    return sendMessage(message, MSG_TYPES.info, options)
}

export const notificationSuccess = (message, options) => {
    return sendMessage(message, MSG_TYPES.success, options)
}

export default {
    error: notificationError,
    warn: notificationWarning,
    info: notificationInfo,
    success: notificationSuccess,
}
