import { createActionsCreator } from '../actionCreatorsFactory'

const createAction = createActionsCreator('SAGAS/TRANSACTIONS')

export const getTransactions = createAction('GET_TRANSACTIONS')
export const getExportAllTransactions = createAction('EXPORT_ALL')
export const getTransactionsForUser = createAction('GET_USER_TRANSACTIONS')
export const getTransaction = createAction('GET_TRANSACTION')
export const getReadyTransactions = createAction('GET_READY_USER')
export const getAllReadyTransactions = createAction('GET_ALL_READY')
export const markTransactionsAsCleared = createAction('MARK_CLEARED')
export const markAllTransactionsAsCleared = createAction('MARK_ALL_CLEARED')
export const changeTransactionStatus = createAction('CHANGE_STATUS')
export const changeAllTransactionsStatus = createAction('CHANGE_ALL_STATUS')
export const getTransactionCategories = createAction('GET_CATEGORIES')
export const getRejectedTransactions = createAction('GET_REJECTED')
export const getAllRejectedTransactions = createAction('GET_ALL_REJECTED')
export const getExportAllRejectedTransactions = createAction('GET_EXPORT_ALL_REJECTED')
export const getBillingTransactions = createAction('GET_BILLING_REJECTED')
