import { baseApi } from '../../apis'
import appConstants from '../../../config/appConstants'

const commonApi = {
    updateAllowedCategories(userId, allowedCategories) {
        return baseApi.post({
            url: '/business/mcclimit',
            body: { userId, allowedCategories },
        })
    },
    getSettings() {
        return baseApi.get({
            url: '/business/settings',
        })
    },
    updateOwnTransactionApproval(body) {
        return baseApi.patch({
            url: '/business/owntransactionapproval',
            body,
        })
    },
    updateThreshold(body) {
        return baseApi.patch({
            url: '/business/receiptthreshold',
            body,
        })
    },
    updateInitialCardLimit(body) {
        return baseApi.post({
            url: '/business/updateInitialCardLimits',
            body,
        })
    },
    updateReminderFrequency(reminderFrequency) {
        return baseApi.patch({
            url: '/business/reminderfrequency',
            body: { reminderFrequency },
        })
    },
    getRole() {
        return baseApi.get({
            url: appConstants.api.urls.common.role,
        })
    },
    updateRolePermission(userId, values) {
        return baseApi.post({
            url: `/role/${userId}/update`,
            body: { ...values },
        })
    },
    getPrevUser() {
        return baseApi.getSite({
            url: appConstants.api.urls.common.name,
        })
    },
    getPrevAvatar() {
        return baseApi.getSite({
            url: appConstants.api.urls.common.avatar,
        }, {
            responseType: 'blob',
        })
    },
    getDownloadFile(fileId) {
        return baseApi.getDownloadSite({
            url: `${appConstants.api.urls.common.downloadFile}${fileId}`,
        }, {
            responseType: 'blob',
        })
    },
    getDownloadKybFile(fileId) {
        return baseApi.getDownloadSite({
            url: `${appConstants.api.urls.common.downloadKybFile}${fileId}`,
        }, {
            responseType: 'blob',
        })
    },

}

export default commonApi
