import { createActionsCreator } from '../actionCreatorsFactory'

const createAction = createActionsCreator('SAGAS/COMMON')

export const setCurrentApp = createAction('SET_CURRENT_APP')
export const updateAllowedCategories = createAction('UPDATE_ALLOWED_CATEGORIES')
export const getSettings = createAction('SET_SETTINGS')
export const getRole = createAction('GET_ROLE')
export const getUserList = createAction('GET_USER_LIST')
export const updateRoleStatus = createAction('UPDATE_ROLE_STATUS')
export const updateRolePermission = createAction('UPDATE_ROLE_PERMISSION')

export const updateOwnTransactionApproval = createAction('UPDATE_OWN_TRANSACTIONS_APPROVAL')
export const updateThreshold = createAction('UPDATE_THRESHOLD')
export const updateInitialCardLimit = createAction('UPDATE_INIT_CARD_LIMIT')
export const openExternalUrl = createAction('OPEN_EXTERNAL_URL')
export const goBack = createAction('GO_BACK')
export const updateReminderFrequency = createAction('UPDATE_REMINDER_FREQUENCY')
export const getPrevUserAndAvatar = createAction('GET_PREV_USER_AND_AVATAR')
export const getDownloadFile = createAction('GET_DOWNLOAD_FILE')
export const getDownloadKybFile = createAction('GET_DOWNLOAD_KYB_FILE')

