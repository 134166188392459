import { baseApi } from '../../apis'

const statsApi = {
    getPaymentsStatsCount() {
        return baseApi.get({
            url: '/business/payments/stats/count',
        })
    },

    getEmployeesStats() {
        return baseApi.get({
            url: '/business/employees/stats',
        })
    },

    getTransactionBalanceStats() {
        return baseApi.get({
            url: `/transaction/balance/stats`,
        })
    },
    getUserEmployeesStats() {
        return baseApi.get({
            url: `/user/employees/stats`,
        })
    },
    getTransactionsCountStats() {
        return baseApi.get({
            url: `/transaction/count/stats`,
        })
    },
    getTransactionsValueStats() {
        return baseApi.get({
            url: `/transaction/value/stats`,
        })
    },

}

export default statsApi
